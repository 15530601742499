import { Grid, Box } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const checks = [
  {
    label: 'Please always raise the handrail on the bed.',
    key: 'fall_education.checked1',
  },
  {
    label:
      'Keep patient’s  necessities nearby so that one does not fall while reaching for objects.',
    key: 'fall_education.checked2',
  },
  {
    label:
      'Clean up the area around the bed from objects that could lead to falling accidents (water, wires, instruments, etc).',
    key: 'fall_education.checked3',
  },
  {
    label: 'Guardian accompanies a patient for most of the time.',
    key: 'fall_education.checked4',
  },
  {
    label:
      'Guardian needs to help a patient who wakes up at night to use bathroom or going elsewhere.',
    key: 'fall_education.checked5',
  },
  {
    label: 'Do not get up from the bed immediately if you feel dizzy.',
    key: 'fall_education.checked6',
  },
  {
    label: 'If you need help, use a pager to ask for help.',
    key: 'fall_education.checked7',
  },
  {
    label:
      'When walking to a wheelchair be extra careful due to difference in height of the floor.',
    key: 'fall_education.checked8',
  },
];

const EducationList = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="Fall Prevention Education" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        <Grid container xs={12}>
          {checks.map((v, i) => (
            <Grid item xs={12}>
              <Form.MuiCheckbox
                label={v.label}
                disabled={disabled}
                defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                onChange={(_, checked) => {
                  setValue(v.key, checked);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default EducationList;
