import Form from 'components/Form';
import { IFormRegister, IFormWatch, IFormValues } from 'routes/Main/type';

import { Grid, Box, MenuItem } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';

import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormWatch, IFormValues {
  disabled?: boolean;
}

const EmergencyRecord = (props: Props) => {
  const { disabled, register, watch, getValues, setValue } = props;

  const timePickers = [
    {
      title: 'Occurrence Time',
      variable: 'emergency_information.accident_time',
    },
    { title: 'Arrival Time', variable: 'emergency_information.arrival_time' },
  ];

  return (
    <>
      <SectionTitle title="Transfer Summary" />
      <RowContainer xs={12}>
        <RowContent title="Accident date" titleRatio={1} childrenRatio={2}>
          <Form.MuiTextField
            type="date"
            InputLabelProps={{ shrink: true }}
            required={false}
            disabled={disabled}
            {...register('emergency_information.accident_date')}
          />
        </RowContent>
        {timePickers.map(({ title, variable }, _) => (
          <RowContent
            key={variable}
            title={title}
            titleRatio={1}
            childrenRatio={2}
          >
            <MobileTimePicker
              disabled={disabled}
              value={watch(variable) || null}
              onChange={value => setValue(variable, value)}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  required={false}
                  placeholder="00:00"
                  InputProps={{ endAdornment: <AccessTime /> }}
                />
              )}
            />
          </RowContent>
        ))}
        <RowContent title="Mode of arrival" titleRatio={1} childrenRatio={2}>
          <Box display="flex">
            <Form.MuiTextField
              select
              required={false}
              disabled={disabled}
              defaultValue={getValues('emergency_information.visit_method')}
              {...register('emergency_information.visit_method')}
            >
              <MenuItem value="ambulance">Ambulatory</MenuItem>
              <MenuItem value="stretcher">Stretcher</MenuItem>
              <MenuItem value="wx">WC</MenuItem>
              <MenuItem value="police">Police</MenuItem>
              <MenuItem value="medics">Medics</MenuItem>
            </Form.MuiTextField>
          </Box>
        </RowContent>
        <Grid item xs={12}>
          <Form.MuiTextField
            multiline
            minRows={8}
            disabled={disabled}
            required={false}
            {...register('emergency_information.memo')}
            placeholder={'Chief Complaint'}
          />
        </Grid>
      </RowContainer>
    </>
  );
};

export default EmergencyRecord;
