import { IFormRegister } from 'routes/Main/type';

import Form from 'components/Form';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import RowContentPreWrap from './components/RowContent';
import { Box, Typography, useTheme } from '@mui/material';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const Signature = (props: Props) => {
  const { disabled, register } = props;
  const { palette } = useTheme();

  const labels = [
    { title: 'Date', variable: 'date' },
    { title: 'Relationship with a\npatient', variable: 'relationship' },
    {
      title: 'Name of the principal\n(or legal representative)',
      variable: 'name',
    },
    {
      title: 'Sign yourself (or your\nlegal representative)',
      variable: 'sign',
    },
  ];

  const labelsTwo = [
    { title: '', variable: '' },
    { title: "Doctor's name", variable: 'dr_name' },
    { title: "Doctor's signature", variable: 'dr_sign' },
  ];

  return (
    <>
      <RowContainer xs={11.5} sx={{ margin: '20px 0px 30px 0px' }}>
        {labels.map(({ title, variable }, _) => (
          <>
            {Array(2)
              .fill(0)
              .map(() => (
                <RowContent titleRatio={1.5} childrenRatio={2.5} />
              ))}
            {variable === 'date' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.5}
                childrenRatio={2.5}
              >
                <Form.MuiTextField
                  type="date"
                  required={false}
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : (
              <RowContentPreWrap
                key={variable}
                title={title}
                titleRatio={1.5}
                childrenRatio={2.5}
              >
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContentPreWrap>
            )}
          </>
        ))}

        {labelsTwo.map(({ title, variable }, _) => (
          <>
            {Array(2)
              .fill(0)
              .map(() => (
                <RowContent titleRatio={1.5} childrenRatio={2.5} />
              ))}
            {variable === '' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.5}
                childrenRatio={2.5}
              ></RowContent>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.5}
                childrenRatio={2.5}
              >
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}
      </RowContainer>
    </>
  );
};

export default Signature;
