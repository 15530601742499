import { Fragment } from 'react';
import { Grid } from '@mui/material';
import { UseFormGetValues } from 'react-hook-form';

import MuiTable from 'components/MuiTable';
import { IPathology } from 'apis/survey/type';
import { formatStringToDate } from 'utils/formatting';

import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

interface Props {
  getValues: UseFormGetValues<any>;
}

const Pathologies = ({ getValues }: Props) => {
  const pathologies: IPathology[] = getValues('pathologies');

  const columns = [
    { fieldId: 'pt_pathology_no', label: 'No.' },
    { fieldId: 'pathology_time', label: 'Time' },
    { fieldId: 'fee_eng', label: 'Name', width: 300 },
    { fieldId: 'pathology_result', label: 'Result', width: 240 },
    { fieldId: 'pathology_range', label: 'Range', width: 240 },
    { fieldId: 'pathology_note', label: 'Notes', width: 240 },
  ];

  const rows = pathologies?.map(item => ({
    ...item,
    id: item.pt_pathology_no,
    pathology_time: formatStringToDate(item.pathology_time, 'hh:mm a'),
  }));

  return (
    <Fragment>
      <SectionTitle title="Clinical pathology test results" />
      <RowContainer ratio={12}>
        <Grid item xs={12}>
          <MuiTable columns={columns} rows={rows} />
        </Grid>
      </RowContainer>
    </Fragment>
  );
};

export default Pathologies;
