import Form from 'components/Form';

import { Grid, Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks_cardio_1 = [
  {
    label: 'SB',
    key: 'assessment_cardio.checked1',
  },
  {
    label: 'NSR',
    key: 'assessment_cardio.checked2',
  },
  {
    label: 'ST',
    key: 'assessment_cardio.checked3',
  },
  {
    label: 'A-Fib',
    key: 'assessment_cardio.checked4',
  },
  {
    label: 'A-Flutter',
    key: 'assessment_cardio.checked5',
  },
  {
    label: 'A-Paced',
    key: 'assessment_cardio.checked6',
  },
  {
    label: 'V-Paced.',
    key: 'assessment_cardio.checked7',
  },
  {
    label: 'PACs.',
    key: 'assessment_cardio.checked8',
  },
];

const checks_cardio_2 = [
  {
    label: 'PVCs',
    key: 'assessment_cardio.checked9',
  },
  {
    label: 'AICD',
    key: 'assessment_cardio.checked10',
  },
  {
    label: 'Murmur',
    key: 'assessment_cardio.checked11',
  },
  {
    label: 'Block',
    key: 'assessment_cardio.checked12',
  },
];

const checks_cardio_edema1 = [
  {
    label: 'None',
    key: 'assessment_cardio_edema.checked1',
  },
  {
    label: 'Gen',
    key: 'assessment_cardio_edema.checked2',
  },
  {
    label: 'Trace',
    key: 'assessment_cardio_edema.checked3',
  },
  {
    label: '1+',
    key: 'assessment_cardio_edema.checked4',
  },
  {
    label: '2+',
    key: 'assessment_cardio_edema.checked5',
  },
  {
    label: '3+',
    key: 'assessment_cardio_edema.checked6',
  },
];

const checks_cardio_edema2 = [
  {
    label: 'Pitting',
    key: 'assessment_cardio_edema.checked7',
  },
  {
    label: 'Non-Pitting',
    key: 'assessment_cardio_edema.checked8',
  },
];

const checks_cardio_edema3 = [
  {
    label: 'R',
    key: 'assessment_cardio_edema.checked9',
  },
  {
    label: 'L',
    key: 'assessment_cardio_edema.checked10',
  },
  {
    label: 'Bilateral',
    key: 'assessment_cardio_edema.checked11',
  },
];

const checks_cardio_edema4 = [
  {
    label: 'Arms',
    key: 'assessment_cardio_edema.checked12',
  },
  {
    label: 'Legs',
    key: 'assessment_cardio_edema.checked13',
  },
];

const checks_cardio_pulses1 = [
  {
    label: 'DP',
    key: 'assessment_cardio_pulse.checked1',
  },
  {
    label: 'Radial',
    key: 'assessment_cardio_pulse.checked2',
  },
];

const checks_cardio_pulses2 = [
  {
    label: 'Dopplers',
    key: 'assessment_cardio_pulse.checked3',
  },
  {
    label: '+1',
    key: 'assessment_cardio_pulse.checked4',
  },
  {
    label: '+2',
    key: 'assessment_cardio_pulse.checked5',
  },
];

const checks_vte_1 = [
  {
    label: 'SCDs',
    key: 'assessment_vte.checked1',
  },
  {
    label: 'Foot Pumps',
    key: 'assessment_vte.checked2',
  },
  {
    label: 'Heparin',
    key: 'assessment_vte.checked3',
  },
  {
    label: 'Lovenox',
    key: 'assessment_vte.checked4',
  },
  {
    label: 'Coumadin',
    key: 'assessment_vte.checked5',
  },
];

const checks_vte_2 = [
  {
    label: 'Xarelto',
    key: 'assessment_vte.checked6',
  },
  {
    label: 'Eliquls',
    key: 'assessment_vte.checked7',
  },
  {
    label: 'None Needed',
    key: 'assessment_vte.checked8',
  },
  {
    label: 'Needs Order',
    key: 'assessment_vte.checked9',
  },
];

const checks_vte_diet_1 = [
  {
    label: 'Reg',
    key: 'assessment_gastro_diet.checked1',
  },
  {
    label: 'Clear',
    key: 'assessment_gastro_diet.checked2',
  },
  {
    label: 'Full',
    key: 'assessment_gastro_diet.checked3',
  },
  {
    label: 'AHA',
    key: 'assessment_gastro_diet.checked4',
  },
  {
    label: 'ADA',
    key: 'assessment_gastro_diet.checked5',
  },
];

const checks_vte_diet_2 = [
  {
    label: 'Dysphagia  I',
    key: 'assessment_gastro_diet.checked6',
  },
  {
    label: 'Dysphagia II',
    key: 'assessment_gastro_diet.checked7',
  },
  {
    label: 'Dysphagia III',
    key: 'assessment_gastro_diet.checked8',
  },
  {
    label: 'Soft',
    key: 'assessment_gastro_diet.checked9',
  },
  {
    label: 'Renal',
    key: 'assessment_gastro_diet.checked10',
  },
  {
    label: 'NPO',
    key: 'assessment_gastro_diet.checked11',
  },
];

const checks_vte_symptom = [
  {
    label: 'Hypo',
    key: 'assessment_gastro_symptom.checked1',
  },
  {
    label: 'Active',
    key: 'assessment_gastro_symptom.checked2',
  },
  {
    label: 'Hyper',
    key: 'assessment_gastro_symptom.checked3',
  },
  {
    label: 'Nausea',
    key: 'assessment_gastro_symptom.checked4',
  },
  {
    label: 'Vomiting',
    key: 'assessment_gastro_symptom.checked5',
  },
  {
    label: 'Diarrhea',
    key: 'assessment_gastro_symptom.checked6',
  },
];

const checks_vte_tube = [
  {
    label: 'Ostomy',
    key: 'assessment_gastro_tube.checked1',
  },
  {
    label: 'G-tube (LWS)',
    key: 'assessment_gastro_tube.checked2',
  },
  {
    label: 'G-tube (Gravity)',
    key: 'assessment_gastro_tube.checked3',
  },
];

const Assessment3 = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <RowContainer
        xs={12}
        sx={{ margin: '0px 0px 5px 0px', marginLeft: '-25px' }}
      >
        <RowContent title="V/S Trend" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                HR
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_vs_hr')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Temp
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_vs_temp')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                BP
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_vs_bp')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                RR
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_vs_rr')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                O<sub>2</sub>
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_vs_o2')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent
          title="Cardiovascular"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Stack direction="row" spacing={1}>
            {checks_cardio_1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_cardio_2.map((v, i) => {
              return (
                <Grid item xs={i === 6 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Edema
              </Typography>
            </Grid>

            {checks_cardio_edema1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}></Grid>

            {checks_cardio_edema2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}></Grid>

            {checks_cardio_edema3.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}></Grid>

            {checks_cardio_edema4.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Pulses
              </Typography>
            </Grid>

            {checks_cardio_pulses1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}></Grid>

            {checks_cardio_pulses2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent
          title="VTE Prophylaxis"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Stack direction="row" spacing={1}>
            {checks_vte_1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_vte_2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent
          title="Gastrointestinal"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Diet
              </Typography>
            </Grid>
            {checks_vte_diet_1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}></Grid>
            {checks_vte_diet_2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Symptom
              </Typography>
            </Grid>
            {checks_vte_symptom.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Tube
              </Typography>
            </Grid>
            {checks_vte_tube.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default Assessment3;
