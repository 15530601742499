import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import MuiDialog from 'components/MuiDialog';
import { SurveyDialogProps, TSBARDefaultValues } from 'routes/Main/Survey/type';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import CommonPatientInfo from '../components/CommonPatientInfo';
import TextareaSection from '../components/TextAreaSection';

import { updateSBAR } from 'apis/survey';

const SBAR = (props: SurveyDialogProps<TSBARDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TSBARDefaultValues) => {
    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      sbar1_survey: { ...data },
    };

    updateSBAR(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          SBAR
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <TextareaSection
          {...formProps}
          title="S (Situation)"
          registerId="situation"
          required={false}
        />
        <TextareaSection
          {...formProps}
          title="B (Background)"
          registerId="background"
          required={false}
        />
        <TextareaSection
          {...formProps}
          title="A (Assessment)"
          registerId="assessment"
          required={false}
        />
        <TextareaSection
          {...formProps}
          title="R (Recommendation)"
          registerId="recommendation"
          required={false}
        />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default SBAR;
