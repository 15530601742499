import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import regex from 'utils/regex';

import { findKeyValueToStr } from 'utils/convert';
import { createOutHospital } from 'apis/survey';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';
import MuiDialog from 'components/MuiDialog';
import {
  TOutHospitalDefaultValues,
  SurveyDialogProps,
} from 'routes/Main/Survey/type';

import PatientInfo from './PatientInfo';
import DefaultInfo from './DefaultInfo';
import Medicines from './Medicines';
import OutPatients from './OutPatients';
import Education from './Education';
import CheckReservations from './CheckReservations';
import CommonPatientInfo from '../components/CommonPatientInfo';
import { isNumberObject } from 'util/types';

const OutHospital = (props: SurveyDialogProps<TOutHospitalDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    patientInfo,
    onClose,
    nurseName,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode } = useNotification();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, register, getValues, setValue, control, watch } =
    useForm({
      defaultValues,
    });

  const onSubmit = (data: TOutHospitalDefaultValues) => {
    const { default_info } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      out_hospital_survey: {
        patient_name: patientInfo.name,
        patient_id: patientInfo.patient_id,
        date: data.date,
        default_info: findKeyValueToStr(default_info),
        out_hospital_medicines: data.out_hospital_medicines,
        out_patients: data.out_patients,
        check_reservations: data.check_reservations,
        education: data.education,
      },
    };

    const head = request.out_hospital_survey;

    createOutHospital(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = { disabled, register, getValues, setValue, control, watch };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Discharge Record
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <DefaultInfo {...formProps} />
        <Medicines {...formProps} />
        <OutPatients {...formProps} />
        <CheckReservations {...formProps} />
        <Education {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default OutHospital;
