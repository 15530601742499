import Form from 'components/Form';

import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';
import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label:
      "Unable to comprehend the terms of the agreement due to the patient's physical or mental impairment. ",
    key: 'no3_check_01',
  },
  {
    label:
      "It is evident that explaining would have a significantly adverse impact on the patient's mental state.",
    key: 'no3_check_02',
  },
  {
    label:
      'The patient delegates the authority for consent to a specific individual.',
    key: 'no3_check_03',
  },
  {
    label: 'etc',
    key: 'no3_check_04',
  },
];

const RecordInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="3. Reason for the Guardian's Signature (Only if signed by the guardian)" />
      <Box sx={{ margin: '48px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          {checks.map((v, i) => {
            if (v.label === 'etc') {
              return (
                <Grid item xs={12}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                    <Form.MuiTextField
                      sx={{ marginLeft: '20px' }}
                      disabled={disabled}
                      required={false}
                      placeholder="Enter"
                      {...register('no3_input_box')}
                    />
                  </Stack>
                </Grid>
              );
            }
            return (
              <Grid item xs={11.5}>
                <Form.MuiCheckbox
                  label={v.label}
                  disabled={disabled}
                  defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                  onChange={(_, checked) => {
                    setValue(v.key, checked);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <Typography
          sx={{ margin: '30px 0 20px 0', fontSize: '14px', fontWeight: 500 }}
        >
          After hearing the above explanation, the patient/guardian has received
          sufficient information about the use of physical restraints and agrees
          to it.
        </Typography>
      </Box>
    </>
  );
};

export default RecordInfo;
