import { useEffect, useState } from 'react';

import { ReactComponent as FileIcon } from 'assets/file-icon.svg';
import { Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import MuiTextField from 'components/Form/MuiTextField';

import useNotification from 'hooks/useNotification';
import { IFormValues } from 'routes/Main/type';
import { getVideo, deleteVideo } from 'apis/video';
import axios from 'axios';

import DeleteConfirmButton from './components/DeleteConfirmButton';

interface Props extends IFormValues {
  disabled?: boolean;
  user_id: number;
  patient_id: number;
  patient_name: string;
  totalSize: number;
  refresh: boolean;
  setTotalSize: (totalSize: number) => void;
}

const VideoForm = (props: Props) => {
  const {
    disabled,
    user_id,
    patient_id,
    patient_name,
    totalSize,
    setTotalSize,
    refresh,
    setValue,
  } = props;

  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const [files, setFiles] = useState<any[]>([
    { saved: false, path: '', file: null },
    { saved: false, path: '', file: null },
    { saved: false, path: '', file: null },
  ]);

  console.log('files : ', files);

  const [yesDelet, setYesDelet] = useState(false);

  const deleteFile = (i: number) => {
    // const isConfirm = window.confirm('삭제하겠습니까?');
    if (yesDelet) {
      deleteVideo({ user_id, patient_id, video_num: i + 1 })
        .then(res => {})
        .catch(e => onFail('Deletion failed.', e));
    } else {
      return;
    }

    const newSize = Number(
      (totalSize - files[i].file.size / Math.pow(2048, 3)).toFixed(4)
    );
    setTotalSize(newSize < 0 ? 0 : newSize);
    const newFiles = [
      ...files.slice(0, i),
      { saved: false, path: '', file: null },
      ...files.slice(i + 1),
    ];
    setFiles(newFiles);
    setValue('files', newFiles);
  };

  const downloadFile = (i: number) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/file/video_download?user_id=${user_id}&patient_id=${patient_id}&video_num=${
          i + 1
        }`,
        {
          responseType: 'blob',
        }
      )
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = files[i].path;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(e => onFail('Download failed.', e));
  };

  useEffect(() => {
    getVideo({ user_id, patient_id })
      .then(({ data }) => {
        const { file_info, total_video_size } = data;

        setTotalSize(Number((total_video_size / Math.pow(2048, 3)).toFixed(4)));
        let savedFiles = [...files];
        file_info.forEach((file: any) => {
          savedFiles[file.video_num - 1] = {
            saved: true,
            path: file.video_name,
            file: { size: file.video_size },
          };
        });
        while (savedFiles.length < 3) {
          savedFiles.push({ saved: false, path: '', file: null });
        }
        setFiles(savedFiles);
      })
      .catch(e => {
        if (e.response.data.status !== 500)
          onFail('Failed to load core nursing skills video.', e);
      });
  }, [refresh]);

  return (
    <Table sx={{ width: '100%', marginTop: '24px' }} aria-label="simple table">
      <TableBody>
        {files.map((file, i) => (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{ width: '30%' }}>LEVEL {i + 1}</TableCell>
            <TableCell sx={{ width: '30%' }}>{patient_name}</TableCell>
            <TableCell sx={{ width: '30%' }}>
              {file.saved ? (
                <MuiTextField
                  value={files[i].path}
                  InputProps={{ endAdornment: <FileIcon />, readOnly: true }}
                  onClick={() => downloadFile(i)}
                />
              ) : (
                <MuiTextField
                  required={false}
                  type="file"
                  value={files[i].path}
                  onChange={e => {
                    console.log('files : ', files);
                    let newFiles = [...files];
                    console.log('newFiles : ', newFiles);

                    let size = 0;
                    const target = e.target as HTMLInputElement;
                    if (target && target.files) {
                      newFiles[i] = {
                        saved: false,
                        path: target.value,
                        file: target.files[0],
                      };
                      size = target.files[0].size;
                      if (size > 512000000) {
                        return onRequired('REQUIRED.VIDIEO.FORMAT');
                      }
                    }
                    setTotalSize(
                      Number((totalSize + size / Math.pow(2048, 3)).toFixed(4))
                    );
                    setFiles([...newFiles]);
                    setValue('files', newFiles);
                  }}
                  InputProps={{ endAdornment: <FileIcon /> }}
                />
              )}
            </TableCell>

            {files[i].path !== '' ? (
              // <TableCell sx={{ width: '10%' }}>
              //   <Button
              //     variant="contained"
              //     size="small"
              //     onClick={() => deleteFile(i)}
              //   >
              //     삭제
              //   </Button>
              // </TableCell>
              <TableCell sx={{ width: '10%' }}>
                <DeleteConfirmButton
                  yesDelet={yesDelet}
                  setYesDelet={setYesDelet}
                  i={i}
                  deleteFile={deleteFile}
                  title="DELETE"
                  middleTitle="Are you sure you want to delete it?"
                  message={`If you select the Delete button, the contents are not recovered.`}
                  color={'white'}
                />
              </TableCell>
            ) : (
              <TableCell sx={{ width: '10%' }}>
                <Button
                  variant="contained"
                  size="small"
                  disabled
                  onClick={() => deleteFile(i)}
                >
                  DELETE
                </Button>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default VideoForm;
