import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import MuiDialog from 'components/MuiDialog';
import {
  SurveyDialogProps,
  TIntubationDefaultValues,
} from 'routes/Main/Survey/type';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { updateIntubation } from 'apis/survey';

import CommonPatientInfo from '../../components/CommonPatientInfo';
import IntubationC from './IntubationC';
import IntubationA from './IntubationA';
import FoleyCatheter from './FoleyCatheter';
import AVF from './AVF';
import HDCatheter from './HDCatheter';

const Intubation = (props: SurveyDialogProps<TIntubationDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    patientInfo,
    nurseName,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TIntubationDefaultValues) => {
    const {
      cline_information,
      aline_information,
      foley_information,
      avf_information,
      hd_information,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      line_survey: {
        cline_information,
        aline_information,
        foley_information,
        avf_information,
        hd_information,
      },
    };

    console.log(request);
    updateIntubation(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Intubation Record
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <IntubationC {...formProps} />
        <IntubationA {...formProps} />
        <FoleyCatheter {...formProps} />
        <AVF {...formProps} />
        <HDCatheter {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default Intubation;
