import { Stack, TextField } from '@mui/material';

import Form from 'components/Form';

import { IFormRegister } from '../type';

interface Props extends IFormRegister {
  disabled?: boolean;
}

function Soapie({ register, disabled }: Props) {
  return (
    <Stack spacing={2}>
      <Form.Item label="Subjective Data">
        <TextField
          // required
          fullWidth
          multiline
          minRows={2}
          size="small"
          variant="outlined"
          disabled={disabled}
          {...register('subjective')}
        />
      </Form.Item>

      <Form.Item label="Objective Data">
        <TextField
          // required
          fullWidth
          multiline
          minRows={2}
          size="small"
          variant="outlined"
          disabled={disabled}
          {...register('objective')}
        />
      </Form.Item>

      <Form.Item label="Assessment">
        <TextField
          // required
          fullWidth
          multiline
          minRows={2}
          size="small"
          variant="outlined"
          disabled={disabled}
          {...register('assessment')}
        />
      </Form.Item>

      <Form.Item label="Planning">
        <TextField
          // required
          fullWidth
          multiline
          minRows={2}
          size="small"
          variant="outlined"
          disabled={disabled}
          {...register('planning')}
        />
      </Form.Item>

      <Form.Item label="Interventions">
        <TextField
          // required
          fullWidth
          multiline
          minRows={2}
          size="small"
          variant="outlined"
          disabled={disabled}
          {...register('interventions')}
        />
      </Form.Item>

      <Form.Item label="Evaluation">
        <TextField
          // required
          fullWidth
          multiline
          minRows={2}
          size="small"
          variant="outlined"
          disabled={disabled}
          {...register('evaluation')}
        />
      </Form.Item>
    </Stack>
  );
}

export default Soapie;
