import {
  SurveyDialogProps,
  TCoreNursingSkillVideoDefaultValues,
} from '../Survey/type';
import MuiDialog from 'components/MuiDialog';
import { useEffect, useRef, useState } from 'react';
import usePatient from 'store/patient/usePatient';
import html2canvas from 'html2canvas';
import saveAs from 'file-saver';
import useStudent from 'store/student/useStudent';
import { getPatientBarcode } from 'apis/admin';
import useNotification from 'hooks/useNotification';
import { Skeleton, Typography } from '@mui/material';

const PatientLabel = (
  props: SurveyDialogProps<TCoreNursingSkillVideoDefaultValues>
) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { patient, onSelectedPatientInfo } = usePatient();
  const { student_name: userName } = useStudent();
  const { onFail } = useNotification();

  const divRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    if (!divRef.current) return;

    try {
      const div = divRef.current;
      const canvas = await html2canvas(div, { scale: 2 });
      canvas.toBlob(blob => {
        if (blob !== null) {
          saveAs(blob, `${userName}-${patientInfo?.name}.png`);
        }
      });
    } catch (error) {
      console.error('Error converting div to image:', error);
    }
  };

  // 바코드 변수에 담기
  const [imgBarcode, setImgBarcode] = useState('');

  useEffect(() => {
    if (!patient) return;

    // 가상환자 상세정보 요청
    getPatientBarcode({ patient_id: patient.patient_id })
      .then(({ data }) => {
        // console.log('바코드?', data);
        setImgBarcode(data);
      })
      .catch(e => {
        onSelectedPatientInfo(null);
        onFail(`가상환자 데이터 조회에 실패했습니다.`, e);
      });
    // eslint-disable-next-line
  }, [patient]);

  if (!patientInfo) {
    return <Skeleton variant="rectangular" sx={{ flex: 1 }} />;
  }

  const {
    // column1
    patient_id,
    name,
    age,
    gender,
    blood,
    // column2
    height,
    weight,
    admin_hod,
    admin_pod,
    // column3
    department,
    ward,
    room,
    main_doctor,
    // column4
    disease_main,
    disease_sub,
  } = patientInfo;

  const test = ` ${ward} / ${room}`;
  // console.log(test);

  return (
    <MuiDialog.SurveyFormTwo title={title} isOpen={isOpen} onClose={onClose}>
      <div
        onClick={handleDownload}
        ref={divRef}
        style={{
          width: '288px',
          height: '90px',
          backgroundColor: 'white',
          cursor: 'pointer',

          marginTop: '50px',
          padding: '3px 10px 3px 10px',
          alignItems: 'center',
          justifyContent: 'center',

          display: 'flex',
          border: '1px solid black',
        }}
      >
        <div
          style={{
            width: '170px',
          }}
        >
          <div>
            <Typography sx={{ fontSize: '10px' }}>{patient_id}</Typography>
          </div>

          <div
            style={{
              maxWidth: '127px',
            }}
          >
            <Typography
              sx={{
                fontSize: '10px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {ward}/{room}
            </Typography>
          </div>
          <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>
            {name}
          </Typography>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Typography sx={{ fontSize: '10px' }}>
              {age}/{gender === 2 ? 'M' : 'F'}
            </Typography>
            <Typography sx={{ fontSize: '10px' }}>{blood}</Typography>
          </div>
        </div>

        <div style={{}}>
          <img
            src={imgBarcode}
            alt="바코드"
            style={{ height: '30px', marginLeft: '10px' }}
          />
        </div>
      </div>
    </MuiDialog.SurveyFormTwo>
  );
};

export default PatientLabel;
