import Form from 'components/Form';

import { Fragment } from 'react';
import { Grid } from '@mui/material';

import RowContainer from '../components/RowContainer';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import { Stack } from '@mui/system';
import RowContent from '../components/RowContent';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'DM',
    key: 'background_pmh.checked1',
  },
  {
    label: 'CHF',
    key: 'background_pmh.checked2',
  },
  {
    label: 'HTN',
    key: 'background_pmh.checked3',
  },
  {
    label: 'CAD',
    key: 'background_pmh.checked4',
  },
  {
    label: 'PCI',
    key: 'background_pmh.checked5',
  },
  {
    label: 'HLD',
    key: 'background_pmh.checked6',
  },
  {
    label: 'PVD',
    key: 'background_pmh.checked7',
  },
  {
    label: 'GERD',
    key: 'background_pmh.checked8',
  },
];

const checks2 = [
  {
    label: 'COPD',
    key: 'background_pmh.checked9',
  },
  {
    label: 'Asthma',
    key: 'background_pmh.checked10',
  },
  {
    label: 'CKD',
    key: 'background_pmh.checked11',
  },
  {
    label: 'ESRD',
    key: 'background_pmh.checked12',
  },
  {
    label: 'Smoker',
    key: 'background_pmh.checked13',
  },
  {
    label: 'Drug Abuse',
    key: 'background_pmh.checked14',
  },
  {
    label: 'Psych',
    key: 'background_pmh.checked15',
  },
  {
    label: 'CVA',
    key: 'background_pmh.checked16',
  },
];

const checks3 = [
  {
    label: 'Dementia',
    key: 'background_pmh.checked17',
  },
  {
    label: 'Hypothyroid',
    key: 'background_pmh.checked18',
  },
  {
    label: 'CA',
    key: 'background_pmh.checked19',
  },
];

const checks_Tests = [
  {
    label: 'MRI',
    key: 'background_tests.checked1',
  },
  {
    label: 'X-Ray',
    key: 'background_tests.checked2',
  },
  {
    label: 'CT',
    key: 'background_tests.checked3',
  },
  {
    label: 'Endo',
    key: 'background_tests.checked4',
  },
  {
    label: 'US',
    key: 'background_tests.checked5',
  },
  {
    label: 'Cath',
    key: 'background_tests.checked6',
  },
  {
    label: 'Echo EF',
    key: 'background_tests.checked7',
  },
];

const Background = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="B (Background)" />
      <RowContainer
        xs={12}
        sx={{ margin: '20px auto 20px auto', marginLeft: '-25px' }}
      >
        <RowContent title="PMH" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks.map((v, i) => {
              return (
                <Grid item xs={i === 7 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks2.map((v, i) => {
              return (
                <Grid item xs={i === 7 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks3.map((v, i) => {
              return (
                <Grid item xs={i === 7 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Tests" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks_Tests.map((v, i) => {
              if (i === 6) {
                return (
                  <Grid item xs={2.5}>
                    <Stack direction="row">
                      <Form.MuiCheckbox
                        label={v.label}
                        disabled={disabled}
                        defaultValue={
                          Boolean(getValues(v.key)) ? [v.label] : []
                        }
                        onChange={(_, checked) => {
                          setValue(v.key, checked);
                        }}
                      />
                      <Form.MuiTextField
                        required={false}
                        sx={{ width: '190px', marginLeft: '20px' }}
                        disabled={disabled}
                        placeholder="Enter"
                        {...register(`background_tests_input`)}
                      />
                    </Stack>
                  </Grid>
                );
              }
              return (
                <Grid item xs={i === 7 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Background;
