import { Fragment, useState } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import CheckboxGroup from './components/CheckboxGroup';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import ShortRowContent from './components/RowContentSub';

import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const BodyStatus = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  const [cycle, setCycle] = useState(getValues('body_status.cycle.value'));
  const [breath, setBreath] = useState(getValues('body_status.breath.value'));
  const [camouflage, setCamouflage] = useState(
    getValues('body_status.camouflage.value')
  );
  const [nerve, setNerve] = useState(getValues('body_status.nerve.value'));
  const [skin, setSkin] = useState(getValues('body_status.skin.value'));

  return (
    <Fragment>
      <SectionTitle title="ROS(Review of System)" />
      <RowContainer xs={12}>
        <ShortRowContent title="Cardiovascular System">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('body_status.cycle.value')}
              onChange={v => {
                setValue('body_status.cycle.value', v);
                setCycle(v);
              }}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              disabled={cycle === '1' ? true : false}
              i18nKey="HOSPITALIZATION.BODY.CYCLE"
              values={[1, 2, 3, 4, 0]}
              defaultValue={getValues('body_status.cycle.checked')}
              onChange={v => setValue('body_status.cycle.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={cycle === '1' ? true : false}
              placeholder="Enter"
              sx={{ width: '120px' }}
              {...register('body_status.cycle.input')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Respiratory System">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('body_status.breath.value')}
              onChange={v => {
                setValue('body_status.breath.value', v);
                setBreath(v);
              }}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.BODY.BREATH"
              values={[1, 2, 3, 4, 0]}
              disabled={breath === '1' ? true : false}
              defaultValue={getValues('body_status.breath.checked')}
              onChange={v => setValue('body_status.breath.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={breath === '1' ? true : false}
              placeholder="Enter"
              sx={{ width: '120px' }}
              {...register('body_status.breath.input')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Gastrointestinal System">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('body_status.camouflage.value')}
              onChange={v => {
                setValue('body_status.camouflage.value', v);
                setCamouflage(v);
              }}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              disabled={camouflage === '1' ? true : false}
              i18nKey="HOSPITALIZATION.BODY.CAMOUFLAGE"
              values={[1, 2, 3, 4, 0]}
              defaultValue={getValues('body_status.camouflage.checked')}
              onChange={v => setValue('body_status.camouflage.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={camouflage === '1' ? true : false}
              sx={{ width: '120px' }}
              placeholder="Enter"
              {...register('body_status.camouflage.input')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Nervous System">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('body_status.nerve.value')}
              onChange={v => {
                setValue('body_status.nerve.value', v);
                setNerve(v);
              }}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.BODY.NERVE"
              values={[1, 2, 3, 4, 0]}
              disabled={nerve === '1' ? true : false}
              defaultValue={getValues('body_status.nerve.checked')}
              onChange={v => setValue('body_status.nerve.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={nerve === '1' ? true : false}
              placeholder="Enter"
              sx={{ width: '120px' }}
              {...register('body_status.nerve.input')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Skin">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('body_status.skin.value')}
              onChange={v => {
                setValue('body_status.skin.value', v);
                setSkin(v);
              }}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.BODY.SKIN"
              values={[1, 2, 3, 4, 0]}
              disabled={skin === '1' ? true : false}
              defaultValue={getValues('body_status.skin.checked')}
              onChange={v => setValue('body_status.skin.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={skin === '1' ? true : false}
              placeholder="Enter"
              sx={{ width: '120px' }}
              {...register('body_status.skin.input')}
            />
          </Stack>
        </ShortRowContent>
      </RowContainer>
    </Fragment>
  );
};

export default BodyStatus;
