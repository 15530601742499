import { Fragment } from 'react';
import { Grid, GridSize, Typography } from '@mui/material';

interface Props {
  title?: string;
  children?: React.ReactNode;
  titleRatio?: GridSize;
  childrenRatio?: GridSize;
}

const RowContent = (props: Props) => {
  const { title, titleRatio = 2, children, childrenRatio = 10 } = props;

  return (
    <Fragment>
      <Grid item xs={titleRatio}>
        <Typography
          noWrap
          variant="caption"
          fontWeight={500}
          lineHeight="38px"
          fontSize={12}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={childrenRatio} sx={{ display: 'flex' }}>
        {children}
      </Grid>
    </Fragment>
  );
};

export default RowContent;
