import { forwardRef } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, Box } from '@mui/material';

interface Props extends CheckboxProps {
  label: string;
  sub?: string;
  sup?: string;
  defaultValue: string[];
}

const MuiCheckboxSubSup = forwardRef(
  ({ label, sub, sup, defaultValue, ...props }: Props, ref) => (
    <FormControlLabel
      label={
        <Box component="div" fontSize={12}>
          {label}
          {sub ? <sub>{sub}</sub> : sup ? <sup>{sup}</sup> : null}
        </Box>
      }
      inputRef={ref}
      control={
        <Checkbox
          size="small"
          value={label}
          defaultChecked={defaultValue.includes(label)}
          {...props}
        />
      }
      sx={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
    />
  )
);

export default MuiCheckboxSubSup;
