import Item from './Item';
import MuiCheckbox from './MuiCheckbox';
import MuiCheckboxGroup from './MuiCheckboxGroup';
import MuiRadioGroup from './MuiRadioGroup';
import MuiSelect from './MuiSelect';
import MuiTextField from './MuiTextField';
import Password from './Password';
import MuiCheckboxSubSup from './MuiCheckboxSubSup';

import adornment from './adornment';
import adornmentSup from './adornmentSup';
import { requiredSelect } from './requiredItems';

const Form = {
  Item,
  MuiCheckbox,
  MuiCheckboxSubSup,
  MuiCheckboxGroup,
  MuiRadioGroup,
  MuiSelect,
  MuiTextField,
  Password,

  adornment,
  adornmentSup,
  requiredSelect,
};

export default Form;
