import { Box, Typography } from '@mui/material';

import SectionTitle from '../../components/SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Most common reactions to fresh blood or blood products that are being transfused',
  'are:',
  'High temperature',
  'rash, itching and hives',
  'feeling a bit unwell',
  'Rare risks are:',
  'having too much blood/fluids giving you shortness of breath.',
  'hemolysis, the abnormal breakdown of red blood cells.',
  'the development of antibodies which may complicate future transfusions. If these complications develop in women they can potentially cause problems for all current and future \n     unborn babies.',
  'lung injury causing shortness of breath.',
  'the spread of viral or other infectious germs from the blood of the donors.',
  'very rarely, these above reactions can cause severe harm or possibly death.',
  'There are specific problems for long term multiple transfusions that may be relevant to tour medical condition. (Your Doctor will discuss these with you)',
];

const CContents = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="C. Risks of Blood and/or Blood Products Transfusion" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{ fontWeight: '500', fontSize: '15px', lineHeight: '24px' }}
              >
                {v}
              </Typography>
            );
          }
          if (i === 1 || i === 5) {
            return (
              <Typography
                sx={{ fontWeight: '400', fontSize: '15px', lineHeight: '24px' }}
              >
                <p />
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              • {v}
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default CContents;
