import { Fragment } from 'react';
import { Typography, Grid, Box, Stack } from '@mui/material';
import Form from 'components/Form';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'HTN',
    key: 'no1_2.checked1',
  },
  {
    label: 'DM',
    key: 'no1_2.checked2',
  },
  {
    label: 'Hep',
    key: 'no1_2.checked3',
  },
  {
    label: 'TB',
    key: 'no1_2.checked4',
  },
  {
    label: 'Heart disease',
    key: 'no1_2.checked5',
  },
  {
    label: 'Kidney disease',
    key: 'no1_2.checked6',
  },
  {
    label: 'Respiratory disease',
    key: 'no1_2.checked7',
  },
  {
    label: 'Allergy',
    key: 'no1_2.checked8',
  },
  {
    label: 'Bleeding tendency',
    key: 'no1_2.checked9',
  },
  {
    label: 'etc',
    key: 'no1_2.checked10',
  },
];

const PatientInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="1. Current Status of the Patient" />

      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        <Typography
          sx={{ lineHeight: '20px', fontSize: '14px', marginBottom: '10px' }}
        >
          1) Diagnostic name
        </Typography>
        <Form.MuiTextField
          required={false}
          disabled={disabled}
          {...register('no1_1')}
        />
        <Typography
          sx={{
            lineHeight: '20px',
            margin: '0px 0 0 0px',
            fontSize: '14px',
            marginTop: '10px',
          }}
        >
          2) Medical History
        </Typography>
        <Grid container xs={12}>
          {checks.map((v, i) => {
            if (v.label === 'etc') {
              return (
                <Grid item xs={2.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                    <Form.MuiTextField
                      required={false}
                      sx={{ width: '120px', marginLeft: '20px' }}
                      disabled={disabled}
                      placeholder="Enter"
                      {...register('no1_3')}
                    />
                  </Stack>
                </Grid>
              );
            }
            return (
              <Grid item xs={2.4}>
                <Form.MuiCheckbox
                  label={v.label}
                  disabled={disabled}
                  defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                  onChange={(_, checked) => {
                    setValue(v.key, checked);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientInfo;
