import { Box, Typography } from '@mui/material';

import SectionTitle from './SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'No matter the circumstances, to us each patient is an individual, with unique needs and concerns. Our mission is to provide the best medical care and service in a spiritual environment. \nOur mission keeps you, our patient and our guest, at the forefront of all we do. During your time here with us, you may have questions about the hospital, about your stay, and about the \nmany services we offer patients and guests.',
  'This patient information guide was written and designed to help answer those questions. If you need additional information or assistance, Please contact our patient information desk at \n0000-0000.',
  'We understand that hospitalization can be a stressful time for patients and their families. Be assured that we are here to assist you. And please let any hospital employee know how we \ncan make your stay with us more comfortable or pleasant.',
  'If you have a complaint about the quality of care, your complaint can be submitted via online at ___ by fax to Office of Quality Monitoring 0000-0000, or mail your concern to Office of \nQuality Monitoring. If you have any questions about how to file a complaint, you may contact the Office of Quality Monitoring at 0000-0000.',
];

const contents2 = [
  'Admission',
  'Most inpatients are pre-admitted to the hospital or admitted through the Emergency Room. If you have questions about the admitting process, call the Admitting Office at 0000-0000. For security reasons, the Office of Admission, where admissions are handled, is closed to the public 10:00 pm - 5:30 am daily. During these hours the only public entrance to the Hospital, \nwhich is through the Emergency Room controlled access area.',
];

const contents3 = [
  'Patient Confidentiality',
  `You may request a "no information" status upon admission, or at any time during your stay, by calling the Admitting Office at 0000-0000. "No information" means that, by law, we \ncannot confirm to callers and visitors that you are being treated at the hospital as an inpatient, outpatient or Emergency Room patient. Callers and visitors requesting patient \ninformation will not be given your room number or the direct phone number to your room, but you may share this information with friends and family as you see fit. Likewise, you will \nnot receive flowers, mail or other deliveries unless they are addressed specifically to your correct room number. If you have questions, please call Admitting at 0000-0000.`,
];

const contents4 = [
  'Room Assignments',
  'We have deluxe, private, semi-private and general rooms available. Although we cannot guarantee a specific type of room, every effort will be made to meet your room requests.',
];

const FirstContents = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <SectionTitle title="When You Arrive" />

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents2.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents2.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents3.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents3.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents4.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents4.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default FirstContents;
