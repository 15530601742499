import { Box, Typography, useTheme } from '@mui/material';

import SectionTitle from './components/SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'I request limited emergency care as described here.',

  'I understand DNR means that if my heart stops beating or if I stop breathing, no medical procedure to restart breathing or heart functioning will be instituted.',

  'I understand this decision will not prevent me from obtaining other emergency medical care by prehospital emergency medical care personnel and/or medical care directed by a physician \nprior to my death.',

  'I understand that I may revoke this directive at anytime by destroying this form or by clearly indicating that I have changed my mind.',

  'I give permission for this information to be given to the hospital, emergency care personnel, doctors, nurses or other health personnel as necessary to implement this directive.',

  `I hereby agree to the "Do Not Resuscitate" order. `,
];

const DNRComponents = (props: Props) => {
  const { disabled } = props;
  const { palette } = useTheme();

  return (
    <>
      <SectionTitle title="Do Not Resuscitate Form" />

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 10px auto',
        }}
      >
        {contents.map((v, i) => {
          if (i === 5) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                  color: `${palette.primary.main}`,
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default DNRComponents;
