import { IFormValues, IFormRegister } from 'routes/Main/type';
import { useState } from 'react';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentNowrap';
import SectionTitle from '../components/SectionTitle';
import MuiRadioMedication from './components/MuiRadioMedication';
import MuiRadioTwo from './components/MuiRadioMedicationTwo';

interface Props extends IFormValues, IFormRegister {
  disabled?: boolean;
}

const MedicationType = (props: Props) => {
  const { disabled, getValues, setValue, register } = props;
  const [confirm, setConfirm] = useState(
    getValues('medication_type.confirm_error')
      ? getValues('medication_type.confirm_error')
      : -1
  );
  return (
    <>
      <SectionTitle title="Incident Type - Medication Error" />
      <RowContainer xs={14}>
        <RowContent
          title="Prescription error"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiRadioMedication
            i18nKey="SAFETY.TYPE.PRESCRIPTION"
            values={[1, 2, 3, 4, 0]}
            disabled={disabled}
            defaultValue={getValues('medication_type.prescription_error')}
            onChange={v => setValue('medication_type.prescription_error', v)}
            width="auto"
          />
          {/* <Form.MuiTextField
            required={false}
            fullWidth={false}
            disabled={disabled}
            placeholder="Enter"
            sx={{ marginLeft: '10px' }}
            {...register('medication_type.prescription_error_etc')}
          /> */}
        </RowContent>
        <RowContent
          title="Drug Preparation error"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiRadioMedication
            i18nKey="SAFETY.TYPE.PRESCRIPTION"
            values={[1, 2, 3, 4, 0]}
            disabled={disabled}
            defaultValue={getValues('medication_type.drug_preparation_error')}
            onChange={v =>
              setValue('medication_type.drug_preparation_error', v)
            }
            width="auto"
          />
          {/* <Form.MuiTextField
            required={false}
            fullWidth={false}
            disabled={disabled}
            placeholder="Enter"
            sx={{ marginLeft: '10px' }}
            {...register('medication_type.drug_preparation_error_etc')}
          /> */}
        </RowContent>
        <RowContent title="Confirm error" titleRatio={2} childrenRatio={10}>
          <MuiRadioTwo
            i18nKey="SAFETY.TYPE.CONFIRM"
            defaultValue={confirm}
            value={confirm}
            values={[1, 2]}
            disabled={disabled}
            onChange={v => {
              setValue('medication_type.confirm_error', v);
              setConfirm(v);
              if (v !== 0) setValue('vascular_access_etc', '');
            }}
          />
        </RowContent>
        <RowContent titleRatio={2} childrenRatio={10}>
          <MuiRadioTwo
            i18nKey="SAFETY.TYPE.CONFIRM"
            defaultValue={confirm}
            value={confirm}
            values={[3, 4]}
            disabled={disabled}
            onChange={v => {
              setValue('medication_type.confirm_error', v);
              setConfirm(v);
              if (v !== 0) setValue('vascular_access_etc', '');
            }}
          />
        </RowContent>
        <RowContent titleRatio={2} childrenRatio={10}>
          <MuiRadioTwo
            i18nKey="SAFETY.TYPE.CONFIRM"
            defaultValue={confirm}
            value={confirm}
            values={[5, 6]}
            disabled={disabled}
            onChange={v => {
              setValue('medication_type.confirm_error', v);
              setConfirm(v);
              if (v !== 0) setValue('vascular_access_etc', '');
            }}
          />
        </RowContent>
        <RowContent titleRatio={2} childrenRatio={10}>
          <MuiRadioTwo
            i18nKey="SAFETY.TYPE.CONFIRM"
            defaultValue={confirm}
            value={confirm}
            values={[7]}
            disabled={disabled}
            onChange={v => {
              setValue('medication_type.confirm_error', v);
              setConfirm(v);
              if (v !== 0) setValue('vascular_access_etc', '');
            }}
          />
        </RowContent>

        <RowContent
          title="Interpretation error"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiRadioTwo
            i18nKey="SAFETY.TYPE.INTERPRETATION"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('medication_type.interpretation_error')}
            onChange={v => setValue('interpretation_error', v)}
            width="auto"
          />
        </RowContent>
      </RowContainer>
    </>
  );
};

export default MedicationType;
