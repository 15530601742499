import Form from 'components/Form';

import { Grid, Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import RowContentPre from './components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks_ivf = [
  {
    label: 'NS',
    key: 'assessment_drips_ivf.checked1',
  },
  {
    label: '1/2 NS',
    key: 'assessment_drips_ivf.checked2',
  },
  {
    label: 'D5 1/2',
    key: 'assessment_drips_ivf.checked3',
  },
  {
    label: 'D5 NS',
    key: 'assessment_drips_ivf.checked4',
  },
  {
    label: 'LR',
    key: 'assessment_drips_ivf.checked5',
  },
  {
    label: 'Abx',
    key: 'assessment_drips_ivf.checked6',
  },
  {
    label: 'etc.',
    key: 'assessment_drips_ivf.checked7',
  },
];

const checks_drips = [
  {
    label: 'Heparin',
    key: 'assessment_drips_drips.checked1',
  },
  {
    label: 'Blood',
    key: 'assessment_drips_drips.checked2',
  },
  {
    label: 'TPN',
    key: 'assessment_drips_drips.checked3',
  },
  {
    label: 'Dilt',
    key: 'assessment_drips_drips.checked4',
  },
];

const checks_neuro = [
  {
    label: 'A&O',
    key: 'assessment_neuro.checked1',
  },
  {
    label: 'confused',
    key: 'assessment_neuro.checked2',
  },
];

const checks_neuro_activity = [
  {
    label: 'Up and lib',
    key: 'assessment_neuro_activity.checked1',
  },
  {
    label: 'Bed-rest',
    key: 'assessment_neuro_activity.checked2',
  },
  {
    label: 'Walker',
    key: 'assessment_neuro_activity.checked3',
  },
  {
    label: 'Cane',
    key: 'assessment_neuro_activity.checked4',
  },
  {
    label: 'Neuro Ckecks',
    key: 'assessment_neuro_activity.checked5',
  },
  {
    label: 'Restraints',
    key: 'assessment_neuro_activity.checked6',
  },
  {
    label: 'Bed Alarm',
    key: 'assessment_neuro_activity.checked7',
  },
];

const checks_res = [
  {
    label: 'Room air',
    key: 'assessment_res.checked1',
  },
  {
    label: 'NRB',
    key: 'assessment_res.checked2',
  },
  {
    label: 'CPAP',
    key: 'assessment_res.checked3',
  },
  {
    label: 'BIPAP',
    key: 'assessment_res.checked4',
  },
  {
    label: 'O',
    key: 'assessment_res.checked5',
  },
];

const checks_res_sound = [
  {
    label: 'Clear',
    key: 'assessment_res_input_sound.checked1',
  },
  {
    label: 'Diminished',
    key: 'assessment_res_input_sound.checked2',
  },
  {
    label: 'Wheezing',
    key: 'assessment_res_input_sound.checked3',
  },
  {
    label: 'Crackles',
    key: 'assessment_res_input_sound.checked4',
  },
  {
    label: 'Coarse',
    key: 'assessment_res_input_sound.checked5',
  },
];

const checks_res_treat = [
  {
    label: 'Nebs',
    key: 'assessment_res_input_treat.checked1',
  },
  {
    label: 'IS',
    key: 'assessment_res_input_treat.checked2',
  },
  {
    label: 'CPT',
    key: 'assessment_res_input_treat.checked3',
  },
];

const checks_res_cough = [
  {
    label: 'Productive',
    key: 'assessment_res_input_cough.checked1',
  },
  {
    label: 'Non-productive',
    key: 'assessment_res_input_cough.checked2',
  },
];

const Assessment2 = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <RowContainer
        xs={12}
        sx={{ margin: '20px auto 5px auto', marginLeft: '-25px' }}
      >
        <RowContentPre
          title="Drips,
Fluids"
          titleRatio={1.2}
          childrenRatio={10.3}
        >
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                IVF
              </Typography>
            </Grid>
            {checks_ivf.map((v, i) => {
              return (
                <Grid item xs={i === 6 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              sx={{ width: '85px' }}
              disabled={disabled}
              placeholder="Enter"
              {...register('assessment_drips_ivf_input')}
            />
          </Stack>
        </RowContentPre>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                IV Rate
              </Typography>
            </Grid>
            <Grid item xs={2.7}>
              <Stack direction="row" spacing={3}>
                <Form.MuiTextField
                  required={false}
                  sx={{ width: '190px' }}
                  disabled={disabled}
                  InputProps={{ ...Form.adornment('#', '') }}
                  {...register('assessment_drips_iv_input')}
                />
                <Form.MuiTextField
                  required={false}
                  sx={{ width: '190px' }}
                  disabled={disabled}
                  placeholder="Enter"
                  InputProps={{ ...Form.adornment('', 'u/kg/hr') }}
                  {...register('assessment_drips_iv_date')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Drips
              </Typography>
            </Grid>
            {checks_drips.map((v, i) => {
              return (
                <Grid item xs={i === 6 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Neuro" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks_neuro.map((v, i) => {
              if (i === 0) {
                return (
                  <Grid item xs={2.9}>
                    <Stack direction="row">
                      <Form.MuiCheckbox
                        label={v.label}
                        disabled={disabled}
                        defaultValue={
                          Boolean(getValues(v.key)) ? [v.label] : []
                        }
                        onChange={(_, checked) => {
                          setValue(v.key, checked);
                        }}
                      />
                      <Form.MuiTextField
                        required={false}
                        sx={{ width: '150px', marginLeft: '20px' }}
                        disabled={disabled}
                        placeholder="Enter"
                        {...register('assessment_neuro_input')}
                      />
                    </Stack>
                  </Grid>
                );
              }
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Activity
              </Typography>
            </Grid>
            {checks_neuro_activity.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Pain" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Level
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_pain_input1')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Location
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_pain_input2')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Medication
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_pain_input3')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Frequency
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_pain_input4')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="Respiratory" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks_res.map((v, i) => {
              return (
                <Grid item xs={i === 4 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckboxSubSup
                      label={v.label}
                      sub={i === 4 ? '2' : ''}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              sx={{ width: '95px', marginLeft: '20px' }}
              disabled={disabled}
              placeholder="Enter"
              InputProps={{ ...Form.adornment('', 'L') }}
              {...register('assessment_res_input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Trach
              </Typography>
            </Grid>
            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_res_input_trach')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Breath Sounds
              </Typography>
            </Grid>
            {checks_res_sound.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Treatments
              </Typography>
            </Grid>
            {checks_res_treat.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Cough
              </Typography>
            </Grid>
            {checks_res_cough.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default Assessment2;
