import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  CNPSThreeStyledTableCell,
  CNPSFourStyledTableCell,
  CNPSStyledTableCell,
  CNPSStyledTableCellWithoutLeft,
  CNPSStyledTableCellWithoutRight,
  CNPSStyledTableCellWithoutLeftRight,
} from 'routes/Main/style';

import { IFormValues, IFormWatch } from 'routes/Main/type';

import theme from 'styles/theme';

const radioId = ['face', 'activity', 'respiratory', 'vocalization'];
const contentLabel = [
  {
    id: 1,
    ko: 'Face',
    desc: [
      'No particular expression or smile.',
      'Occasional grimace, tearing, and frown ',
      'Frequent frown, crying, closing eyes, mouth twitching and squinting.',
      'Crushing the teeth, pushing  or harming tracheal region.',
    ],
  },
  {
    id: 2,
    ko: 'Activity (Movement)',
    desc: [
      'Lying quietly, normal position',
      'Seeking attention through movement of slow cautious movements',
      'Aggressive behavior; shaking and twisting the entire body',
      'Aggressive behavior; shaking and twisting the entire body ',
    ],
  },
  {
    id: 3,
    ko: 'Respiratory',
    desc: [
      'Alarm does not ring and patient is adapted',
      'Alarm goes off but shuts shortly',
      'Alarm goes off frequently and patient resist respiratory device',
      'Patient resists and fights respiratory device',
    ],
  },
  {
    id: 4,
    ko: 'Vocalization',
    desc: ['Normal Speech', 'Groaning', 'Sobbing out loud', 'Intense crying'],
  },
];
const scoreLabel = [
  { score: '0', label: 'No Pain' },
  { score: '1~3', label: 'A Little Pain' },
  { score: '4~6', label: 'Moderate Pain' },
  { score: '7~10', label: 'Extreme Pain' },
];

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}
const CNPSContents = (props: Props) => {
  const { watch, setValue, getValues, disabled } = props;

  const [sumValue, setSumValue] = useState(0);

  const calculateSumValue = () => {
    setSumValue(
      radioId.reduce((acc, cur) => {
        const value = Number(getValues(cur));
        return value ? acc + value : acc;
      }, 0)
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(e.target.name, e.target.value);
    calculateSumValue();
  };

  useEffect(() => {
    calculateSumValue();
  }, []);

  return (
    <>
      <Box sx={{ width: 'max-content', margin: '60px auto' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <CNPSStyledTableCell
                colSpan={2}
                align="center"
                sx={{ padding: '16px' }}
              ></CNPSStyledTableCell>
              <CNPSStyledTableCellWithoutRight
                align="center"
                sx={{ padding: '16px' }}
              >
                Score
              </CNPSStyledTableCellWithoutRight>
              <CNPSStyledTableCellWithoutLeft
                colSpan={2}
                sx={{ padding: '16px', paddingLeft: '60px' }}
              >
                Description
              </CNPSStyledTableCellWithoutLeft>
            </TableRow>
          </TableHead>
          <TableBody>
            {contentLabel.map(
              (content: { id: number; ko: string; desc: string[] }, i) => {
                if (content.id === 3) {
                  return (
                    <TableRow>
                      <CNPSThreeStyledTableCell
                        align="center"
                        sx={{
                          width: '60px',
                          paddingTop: '160px',
                        }}
                      >
                        {content.id}
                      </CNPSThreeStyledTableCell>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '180px' }}
                      >
                        {content.ko}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCellWithoutRight>
                        {content.desc.map((_, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{ minWidth: '130px', textAlign: 'center' }}
                            >
                              {i} point
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutRight>
                      <CNPSStyledTableCellWithoutLeftRight>
                        {content.desc.map((v, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{ paddingLeft: '60px', minWidth: '650px' }}
                            >
                              {v}
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutLeftRight>
                      <CNPSStyledTableCellWithoutLeft>
                        <RadioGroup
                          name={radioId[content.id - 1]}
                          defaultValue={Number(
                            getValues(radioId[content.id - 1])
                          )}
                        >
                          {content.desc.map((_, i) => (
                            <TableRow
                              sx={{
                                lineHeight: '43.8px',
                                textAlign: 'center',
                                borderBottom:
                                  i !== content.desc.length - 1
                                    ? '1px solid lightgray'
                                    : '',
                              }}
                            >
                              <Box sx={{ width: '180px' }}>
                                <Radio
                                  disabled={disabled}
                                  name={radioId[content.id - 1]}
                                  value={i}
                                  onChange={handleChange}
                                />
                              </Box>
                            </TableRow>
                          ))}
                        </RadioGroup>
                      </CNPSStyledTableCellWithoutLeft>
                    </TableRow>
                  );
                } else if (content.id === 4) {
                  return (
                    <TableRow>
                      <CNPSFourStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '60px' }}
                      ></CNPSFourStyledTableCell>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '180px' }}
                      >
                        {content.ko}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCellWithoutRight>
                        {content.desc.map((_, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{ minWidth: '130px', textAlign: 'center' }}
                            >
                              {i} point
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutRight>
                      <CNPSStyledTableCellWithoutLeftRight>
                        {content.desc.map((v, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{ paddingLeft: '60px', minWidth: '650px' }}
                            >
                              {v}
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutLeftRight>
                      <CNPSStyledTableCellWithoutLeft>
                        <RadioGroup
                          name={radioId[content.id - 1]}
                          defaultValue={Number(
                            getValues(radioId[content.id - 1])
                          )}
                        >
                          {content.desc.map((_, i) => (
                            <TableRow
                              sx={{
                                lineHeight: '43.8px',
                                textAlign: 'center',
                                borderBottom:
                                  i !== content.desc.length - 1
                                    ? '1px solid lightgray'
                                    : '',
                              }}
                            >
                              <Box sx={{ width: '180px' }}>
                                <Radio
                                  disabled={disabled}
                                  name={radioId[content.id - 1]}
                                  value={i}
                                  onChange={handleChange}
                                />
                              </Box>
                            </TableRow>
                          ))}
                        </RadioGroup>
                      </CNPSStyledTableCellWithoutLeft>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '60px' }}
                      >
                        {content.id}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCell
                        align="center"
                        sx={{ padding: '16px', width: '180px' }}
                      >
                        {content.ko}
                      </CNPSStyledTableCell>
                      <CNPSStyledTableCellWithoutRight>
                        {content.desc.map((_, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{ minWidth: '130px', textAlign: 'center' }}
                            >
                              {i} point
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutRight>
                      <CNPSStyledTableCellWithoutLeftRight>
                        {content.desc.map((v, i) => (
                          <TableRow
                            sx={{
                              lineHeight: '44px',
                              borderBottom:
                                i !== content.desc.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{ paddingLeft: '60px', minWidth: '650px' }}
                            >
                              {v}
                            </Box>
                          </TableRow>
                        ))}
                      </CNPSStyledTableCellWithoutLeftRight>
                      <CNPSStyledTableCellWithoutLeft>
                        <RadioGroup
                          name={radioId[content.id - 1]}
                          defaultValue={Number(
                            getValues(radioId[content.id - 1])
                          )}
                        >
                          {content.desc.map((_, i) => (
                            <TableRow
                              sx={{
                                lineHeight: '43.8px',
                                textAlign: 'center',
                                borderBottom:
                                  i !== content.desc.length - 1
                                    ? '1px solid lightgray'
                                    : '',
                              }}
                            >
                              <Box sx={{ width: '180px' }}>
                                <Radio
                                  disabled={disabled}
                                  name={radioId[content.id - 1]}
                                  value={i}
                                  onChange={handleChange}
                                />
                              </Box>
                            </TableRow>
                          ))}
                        </RadioGroup>
                      </CNPSStyledTableCellWithoutLeft>
                    </TableRow>
                  );
                }
              }
            )}
          </TableBody>
        </Table>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          sx={{ marginTop: '20px' }}
        >
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {sumValue} points
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${theme.palette.primary.main}` }}
          >
            {scoreLabel.map(({ score, label }) => (
              <Typography variant="inherit">
                <Box component={'strong'} mr={0.5}>
                  {score} points:
                </Box>
                {label}
              </Typography>
            ))}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CNPSContents;
