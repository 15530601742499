import { Grid, Box } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const checks = [
  {
    label: 'Patient’s right and responsibilities',
    key: 'education_list.checked1',
  },
  {
    label: 'What to bring for your admission',
    key: 'education_list.checked2',
  },
  {
    label:
      'Facilities in your room (bed, bed table, bed light, telephone, TV, emergency call bell, nursing call bell, air-conditioning, heating etc',
    key: 'education_list.checked3',
  },
  {
    label: 'Facilities in the nursing unit',
    key: 'education_list.checked4',
  },
  {
    label: 'Facilities in the hospital',
    key: 'education_list.checked5',
  },
  {
    label: 'Using bedside telephone and accessing internet',
    key: 'education_list.checked6',
  },
  {
    label: 'Calling a nursing requesting assistance',
    key: 'education_list.checked7',
  },
  {
    label: 'Fire&smoke prevention, what to do in case of fire',
    key: 'education_list.checked8',
  },
  {
    label: 'Robbery prevention',
    key: 'education_list.checked9',
  },
  {
    label: 'Injury/Fall prevention',
    key: 'education_list.checked10',
  },
  {
    label: 'Guest visiting hours and meal times',
    key: 'education_list.checked11',
  },
  {
    label:
      'Right to choose a specialist and responsibility to pay the specialists consultation fee',
    key: 'education_list.checked12',
  },
  {
    label: 'Time for Doctor’s rounds',
    key: 'education_list.checked13',
  },
  {
    label:
      'Requesting necessary documents (medical certificates, attendance record)',
    key: 'education_list.checked14',
  },
  {
    label: 'Reporting system for compliments and complaints',
    key: 'education_list.checked15',
  },
  {
    label: 'Requesting a caregiver',
    key: 'education_list.checked16',
  },
  {
    label: 'Going out during hospitalization',
    key: 'education_list.checked17',
  },
  {
    label: 'Customer service',
    key: 'education_list.checked18',
  },
  {
    label: 'Parking',
    key: 'education_list.checked19',
  },
  {
    label: 'Payment during the mid-term of hospitalization',
    key: 'education_list.checked20',
  },
  {
    label: 'Discharge procedures',
    key: 'education_list.checked21',
  },
];

const EducationList = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="Please check off the items below if you/your family are provided information or instruction from hospital staff and fully understand." />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        <Grid container xs={12}>
          {checks.map((v, i) => (
            <Grid item xs={12}>
              <Form.MuiCheckbox
                label={v.label}
                disabled={disabled}
                defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                onChange={(_, checked) => {
                  setValue(v.key, checked);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default EducationList;
