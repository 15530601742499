import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const CVCfour = (props: Props) => {
  const { disabled, register } = props;

  const info = [
    'Regarding the insertion of the catheter through the subclavian or internal jugular vein, potential complications may include puncture of the subclavian artery, damage to the\n     brachial plexus, pneumothorax, hemothorax, air embolism, hemorrhage due to guidewire-related perforation of adjacent vessels, cardiac tamponade, atrial perforation, arrhythmias,\n     and equipment-related issues. If the insertion is done through the femoral vein, complications such as femoral artery puncture, inguinal or retroperitoneal bleeding may occur.',
    'After insertion, there is a risk of bacterial infection, intravascular clot formation within the catheter, and thrombosis or stenosis of the blood vessel at the catheter insertion site',
    'You should place a sandbag for hemostasis on the venous puncture site and the skin incision site for two hours. During this period, minimize changes in position.',
    'The bruising or swelling at the catheter insertion site should subside within a few days.',
    'During the insertion or use of the catheter, complications such as pneumothorax, hemothorax, fluid accumulation, and air embolism may occur.',
    'If there is a shift in the position or blockage of the catheter during use, it is necessary to check, and if an infection related to the catheter occurs, the catheter should be removed.',
    'A dressing is necessary to prevent infection at the insertion site, and sterile saline is periodically injected to prevent catheter blockage.',
  ];

  return (
    <Fragment>
      <SectionTitle title="4. Post-procedural precautions" />
      <Box sx={{ margin: '38px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          {info.map((a, i) => {
            return (
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '30px',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  • {a}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CVCfour;
