import Form from 'components/Form';
import { IPatientInfo } from 'apis/admin/type';
import { IFormRegister } from 'routes/Main/type';
import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';

interface Props extends IFormRegister {
  disabled?: boolean;
  nurseName: string;
  patientInfo: IPatientInfo;
  setOpDate: (opDate: string) => void;
}

const PatientInfo = (props: Props) => {
  const { patientInfo, nurseName, disabled, register, setOpDate } = props;
  const {
    patient_id,
    name,
    gender,
    age,
    height,
    blood,
    weight,
    department,
    ward,
    room,
    admin_hod,
    disease_main,
    disease_sub,
    main_doctor,
  } = patientInfo;

  const staffs = [
    { title: 'Surgeon', variable: 'surgery_information.operating_surgeon' },
    { title: 'Assistant', variable: 'surgery_information.assistant' },
    { title: 'Scrub Nurse', variable: 'surgery_information.scrubbing_nurse' },
    {
      title: 'Circulating Nurse',
      variable: 'surgery_information.circulating_nurse',
    },
  ];

  return (
    <RowContainer xs={12}>
      <RowContent title="Patient’s  Name" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={name} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Registration No." titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={patient_id} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Age" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={age} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Gender" titleRatio={1} childrenRatio={2}>
        <Form.MuiRadioGroup
          i18nKey="GENDER"
          values={[1, 2]}
          value={gender}
          defaultValue={gender}
        />
      </RowContent>
      <RowContent title="Ward" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={ward} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Room" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={room} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Department" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={department} InputProps={{ readOnly: true }} />
      </RowContent>

      <RowContent title="HOD" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={admin_hod} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Height" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={height} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Weight" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={weight} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Blood type" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={blood} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Diagnosis" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={disease_main.disease_eng}
          InputProps={{ readOnly: true }}
        />
      </RowContent>

      {staffs.map(({ title, variable }, _) => (
        <RowContent
          key={variable}
          title={title}
          titleRatio={1}
          childrenRatio={2}
        >
          <MuiTextField {...register(variable)} required={false} />
        </RowContent>
      ))}
    </RowContainer>
  );
};

export default PatientInfo;
