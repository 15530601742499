import { Fragment, useState } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';
import CheckboxDisease from './components/CheckboxDisease';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const DiseaseHistory = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  const [history, setHistory] = useState(
    getValues('disease_history.history.value')
  );

  return (
    <Fragment>
      <SectionTitle title="Medical History" />

      <RowContainer xs={12}>
        <RowContent title="HX">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_history.history.value')}
              onChange={v => {
                setValue('disease_history.history.value', v);
                setHistory(v);
              }}
            />
            <CheckboxDisease
              i18nNullKey="ETC"
              disabled={history === '1' ? true : false}
              i18nKey="HOSPITALIZATION.DISEASE.HISTORY"
              values={[1, 2, 3, 4, 5, 0]}
              defaultValue={getValues('disease_history.history.checked')}
              onChange={v => setValue('disease_history.history.checked', v)}
            />
          </Stack>
          <Form.MuiTextField
            multiline
            minRows={5}
            required={false}
            disabled={disabled}
            placeholder="Enter"
            {...register('disease_history.history.input')}
          />
        </RowContent>
        <RowContent title="ADM/OP history">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues(
                'disease_history.operation_history.value'
              )}
              onChange={v =>
                setValue('disease_history.operation_history.value', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="ex.22. 01. -university hospital-kdney removal"
              {...register('disease_history.operation_history.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="Recent Medication">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_history.latest_medicine.value')}
              onChange={v =>
                setValue('disease_history.latest_medicine.value', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('disease_history.latest_medicine.input')}
            />
          </Stack>
        </RowContent>

        <RowContent title="FHx">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_history.family_history.value')}
              onChange={v =>
                setValue('disease_history.family_history.value', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="ex. Hypertension"
              {...register('disease_history.family_history.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="Drug Allergy">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_history.medicine_allergy.value')}
              onChange={v =>
                setValue('disease_history.medicine_allergy.value', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('disease_history.medicine_allergy.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="Contrast media Allergy">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_history.allergy.value')}
              onChange={v => setValue('disease_history.allergy.value', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('disease_history.allergy.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="Food Allergy">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('disease_history.food_allergy.value')}
              onChange={v => setValue('disease_history.food_allergy.value', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('disease_history.food_allergy.input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default DiseaseHistory;
