import { ListItem, Typography } from '@mui/material';

const CopyRight = () => (
  <ListItem disablePadding>
    <Typography sx={{ fontSize: 10, color: '#fff', mt: 3 }}>
      Copyright © 2022-2025 DKMediInfo. All rights reserved.
    </Typography>
  </ListItem>
);

export default CopyRight;
