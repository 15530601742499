import { Fragment } from 'react';
import Form from 'components/Form';
import { IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import SectionTitle from '../components/SectionTitle';

import MuiCheckboxGroup from './components/MuiCheckboxEconomic';
import SubMuiRadio from './components/RadioEconomy';

interface Props extends IFormValues {
  disabled?: boolean;
}

const EconomyHistory = (props: Props) => {
  const { disabled, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="Economic Power" />
      <RowContainer xs={12}>
        <RowContent
          title="Social Work Team Consultation"
          titleRatio={1.78}
          childrenRatio={10}
        >
          <SubMuiRadio
            i18nKey="NEEDED"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('economy_history.counseling')}
            onChange={v => setValue('economy_history.counseling', v)}
          />
        </RowContent>
        <RowContent
          title="Emotional State"
          titleRatio={1.78}
          childrenRatio={10}
        >
          <MuiCheckboxGroup
            i18nNullKey="ETC"
            i18nKey="HOSPITALIZATION.ECONOMY.MIND"
            values={[1, 2, 3, 4, 5]}
            disabled={disabled}
            defaultValue={getValues('economy_history.mind_status.checked')}
            onChange={v => setValue('economy_history.mind_status.checked', v)}
          />
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default EconomyHistory;
