import { Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import MuiTable from 'components/MuiTable';
import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';
import useTableForm from './components/useTableForm';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const LEFSContents = (props: Props) => {
  const { palette } = useTheme();
  const { radioGroup, sumValues } = useTableForm(props);
  const { getValues } = props;

  const columns = [
    { fieldId: 'title', label: '' },
    { fieldId: '0', label: 'Extreme\ndifficulty (0)' },
    { fieldId: '1', label: 'Quite a bit of\ndifficulty (1)' },
    { fieldId: '2', label: 'Moderate\ndifficulty (2)' },
    { fieldId: '3', label: 'A little bit of\ndifficulty (3)' },
    { fieldId: '4', label: 'No difficulty (4)' },
  ];

  const rows = [
    {
      id: 'lefs01',
      title: 'a. Any of your usual work, housework, or school activities.',
      ...radioGroup({
        key: 'lefs01',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs02',
      title: 'b. Your usual hobbies, recreational or sporting activities.',
      ...radioGroup({
        key: 'lefs02',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs03',
      title: 'c. Getting into or out of the bath.',
      ...radioGroup({
        key: 'lefs03',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs04',
      title: 'd. Walking between rooms.',
      ...radioGroup({
        key: 'lefs04',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs05',
      title: 'e. Putting on your shoes or socks.',
      ...radioGroup({
        key: 'lefs05',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs06',
      title: 'f. Squatting.',
      ...radioGroup({
        key: 'lefs06',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs07',
      title: 'g. Lifting an object, like a bag of groceries from the floor.',
      ...radioGroup({
        key: 'lefs07',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs08',
      title: 'h. Performing light activities around your home.',
      ...radioGroup({
        key: 'lefs08',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs09',
      title: 'i. Performing heavy activities around your home.',
      ...radioGroup({
        key: 'lefs09',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs10',
      title: 'j. Getting into or out of a car.',
      ...radioGroup({
        key: 'lefs10',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs11',
      title: 'k. Walking 2 blocks.',
      ...radioGroup({
        key: 'lefs11',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs12',
      title: 'l. Walking a mile.',
      ...radioGroup({
        key: 'lefs12',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs13',
      title: 'm. Going up or down 10 stairs (about 1 flight of stairs).',
      ...radioGroup({
        key: 'lefs13',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs14',
      title: 'n.  Standing for 1 hour.',
      ...radioGroup({
        key: 'lefs14',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs15',
      title: 'o. Sitting for 1 hour.',
      ...radioGroup({
        key: 'lefs15',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs16',
      title: 'p. Running on even ground.',
      ...radioGroup({
        key: 'lefs16',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs17',
      title: 'q. Running on uneven ground.',
      ...radioGroup({
        key: 'lefs17',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs18',
      title: 'r. Making sharp turns while running fast.',
      ...radioGroup({
        key: 'lefs18',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs19',
      title: 's. Hopping.',
      ...radioGroup({
        key: 'lefs19',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
    {
      id: 'lefs20',
      title: 't. Rolling over in bed.',
      ...radioGroup({
        key: 'lefs20',
        options: [0, 1, 2, 3, 4],
        sx: {
          paddingLeft: '30px',
        },
      }),
    },
  ];
  // console.log('잉', getValues('lefs02'));

  const watchSumValues = () => {
    const values = rows.map(({ id }) => {
      if (props.watch(`${id}`) === undefined) {
        return 0;
      } else {
        return Number(props.watch(`${id}`));
      }
    });
    return sumValues(values);
  };

  return (
    <Fragment>
      {/* <Typography sx={{ fontSize: '14px', margin: '40px 0 -10px 25px' }}>
        이 설문지는 환자의 입장에서 느끼는 무릎 관절의 상태에 대한 질문입니다.이
        정보는 당신이 느끼고 있는 무릎 관절의 증상에 대해 알 수 있도록 도와주며
        당신의 일상 생활을 영위할 수 있게 하는 정보를 줍니다.
        <br />
        각각의 질문에 대해 가장 적당한 하나의 항목에 대해서만 표기해 주십시오.
      </Typography> */}
      <SectionTitle title="Lower Extremity Functional Scale (LEFS)" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {watchSumValues()} / 80
          </Typography>
          {/* <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${palette.primary.main}`, marginTop: ' 40px' }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                최대 80점이 가능하고, 점수가 높을 수록 기능이 좋은 것을 의미한다
              </Box>
            </Typography>
          </Typography> */}
        </Box>
      </Grid>
      {/* <Grid item xs={12} sx={{ marginRight: '10px' }}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            sx={{
              fontSize: '13px',
            }}
          >
            이관우, 정경심, 서현두, 정이정.(2011).하지의 근골격계 기능장애
            환자들을 위한 한국어 판 Lower Extremity Functional Scale의 신뢰도와
            타당도.
            <br />
            특수교육재활과학연구,50(3),451-467.
          </Typography>
        </Box>
      </Grid> */}
    </Fragment>
  );
};

export default LEFSContents;
