import { Box, Typography } from '@mui/material';

import SectionTitle from './SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Provision of Information',
  'You are required to provide accurate and complete information about your present medical condition and any infectious conditions you may have, including past illnesses, \nhospitalizations, medications, Advance Medical Directive (AMD), and other relevant details in order for you to receive appropriate and safe medical treatment.',
  'Please inform us if you have any existing infectious conditions so that appropriate infection control procedures can be taken. Your long term health depends on your responsibility in \nrecognizing the effect of your lifestyle on your personal health.',
  'Advise us of any changes in your health status.',
  'When obtaining copies of your own medical reports, please ensure you have received the correct information before you leave.',
];

const contents2 = [
  'Respect and Consideration',
  'We ask that you and your family members be considerate of other patients in the hospital who also require rest and the attention of our staff; please consider the rights of others, and \nshow respect and courtesy to all staff, doctors and nurses, and visitors.',
  'You are responsible for your own conduct and behaviour during your stay in hospital; any threat, harassment, or intention to cause others harm or disrupt the hospital operations are \nnot acceptable actions.',
  "Please comply with hospital regulations put in place for patients' safety (for example, our policy on visitation).",
  "Please respect the hospitals' equipment and facilities, treat such property with reasonable care, and be conscious in helping us reduce any wastage in precious resources such as water \nusage, etc.",
];

const contents3 = [
  'Admission',
  'Please do not bring any valuables with you, as you are responsible for the safekeeping of your personal possessions.',
  'Please arrive for admission at the appointed time so as to ensure that the necessary pre-surgical preparations can be completed in a safe and controlled manner.',
  'You will be advised of your financial commitment related to your treatment by your consulting physicians as well as surgical and hospital charges.',
  "It is your responsibility to pay your bills or make suitable arrangement to fulfil your financial obligations. By remitting your bill duly and on time, you will help the hospital continue to \nfocus on its work to serve other patients' needs.",
];

const contents4 = [
  'Medication and Treatment',
  'All your medications will be supplied by our Hospital Pharmacy and administered by our staff whilst you are in our Hospitals.',
  'Do not tamper with any Medical Devices.',
  'Please do not bring any medication from home unless you need to show it to your Doctor. You should however, bring along a written list of medications you may be on and show this list \nto your Doctor and the nurses in the ward. Any medication that you bring from home will not be administered and will be returned to you for safekeeping.',
];

const PatientsResponsibility = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="Patient's Responsibility" />
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents2.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents2.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents3.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents3.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents4.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents4.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default PatientsResponsibility;
