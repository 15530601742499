import { useState } from 'react';
import { useForm } from 'react-hook-form';

import regex from 'utils/regex';
import useUser from 'store/user/useUser';
import useNotification from 'hooks/useNotification';
import { postChangePassword, postLogin } from 'apis/account';

import MyPageForm from './MyPageForm';

function MyPage() {
  const {
    student_uuid: userId,
    student_id,
    student_name,
    student_gender,
    student_grade,
    college_name,
    student_birth,
    student_no,
  } = useUser();
  const { onResultCode, onSuccess, onFail, onRequired } = useNotification();

  const { handleSubmit, getValues, register, reset, setValue } = useForm({
    defaultValues: {
      student_id,
      student_name,
      student_gender,
      student_grade,
      college_name,
      student_birth,
      student_no,
    } as any,
  });

  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const onConfirmPassword = () => {
    const password = getValues('password');
    postLogin({ user_email: student_id!, user_password: password }).then(
      ({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        setIsConfirmPassword(true);
      }
    );
  };

  // 비밀번호 변경
  const onSubmit = (data: any) => {
    if (!isConfirmPassword) {
      return onRequired('REQUIRED.PASSWORD.CONFIRMTRUE');
    }

    if (data.newPassword.length > 0 && !regex.password.test(data.newPassword)) {
      // 비밀번호 유효성 검사
      return onRequired('REQUIRED.PASSWORD.FORMAT');
    }

    // 비밀번호 일치 여부
    const isConfirm = data.newPassword === data.newPasswordConfirm;

    if (!isConfirm) {
      return onRequired('REQUIRED.PASSWORD.CONFIRM');
    } else if (
      data.newPassword.length > 0 &&
      data.newPasswordConfirm.length === 0
    ) {
      return onRequired('REQUIRED.PASSWORD.CONFIRMTWO');
    }

    // 이름에 @ 들어간지 확인 후 막는
    if (data.student_name.includes('@')) {
      return onRequired('REQUIRED.NAME.FORMAT');
    }

    const request = {
      userId,
      password: data.password,
      newPassword: data?.newPassword,

      student_name: data.student_name,
    };

    const request1 = {
      userId,
      // password: data.password,

      student_name: data.student_name,
    };

    postChangePassword(data.newPassword !== '' ? request : request1)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        reset();
        setIsConfirmPassword(false);
        onSuccess('Password change was successful.');
      })
      .catch(e => onFail('Password change failed.', e));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MyPageForm
        studentGrade={student_grade!}
        studentGender={student_gender!}
        register={register}
        isConfirmPassword={isConfirmPassword}
        onConfirmPassword={onConfirmPassword}
        getValues={getValues}
        setValue={setValue}
      />
    </form>
  );
}

export default MyPage;
