import useUser from 'store/user/useUser';

import Form from 'components/Form';
import RowContainer from '../Main/Survey/components/RowContainer';
import RowContent from '../Main/Survey/components/RowContent';

import { Typography, Stack, useTheme } from '@mui/material';

interface Props {
  disabled?: boolean;
  totalSize: number;
}

const StudentModeInfo = (props: Props) => {
  const { palette } = useTheme();

  const { totalSize } = props;
  const { student_name, student_no } = useUser();

  return (
    <RowContainer xs={20}>
      <RowContent title="Nurse" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={student_name}
          InputProps={{ readOnly: true }}
          sx={{ marginRight: '20px' }}
        />
      </RowContent>
      <RowContent title="Student number" titleRatio={1.5} childrenRatio={2}>
        <Form.MuiTextField value={student_no} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContent title="Capacity" titleRatio={1} childrenRatio={4.5}>
        <Stack direction="row" spacing={2}>
          <Typography
            sx={{ color: `${palette.primary.main}` }}
            fontSize="14px"
            lineHeight="37.125px"
            display="inline"
          >
            {totalSize + 'GB'}
          </Typography>
          <Typography fontSize="14px" lineHeight="37.125px" display="inline">
            {' '}
            / 10GB
          </Typography>
          <Typography
            sx={{ color: `${palette.primary.main}` }}
            fontSize="14px"
            lineHeight="37.125px"
            display="inline"
          >
            Up to 500MB per file
          </Typography>
        </Stack>
      </RowContent>
    </RowContainer>
  );
};

export default StudentModeInfo;
