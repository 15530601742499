import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentSocial';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const SocialHistory = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <RowContainer>
      <SectionTitle title="Status" mb={0} />

      <RowContainer ratio={12}>
        <RowContent title="Marital Status">
          <Form.MuiRadioGroup
            i18nKey="HOSPITALIZATION.MARRY"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('social_history.marry')}
            onChange={v => setValue('social_history.marry', v)}
          />
        </RowContent>
        <RowContent title="Language">
          <Form.MuiSelect
            disabled={disabled}
            options={[
              'English',
              'Chinese',
              'Spanish',
              'Hindi',
              'Arabic',
              'Bengali',
              'Portuguese',
              'Russian',
              'Japanese',
              'Lahnda',
              'Marathi',
              'Telugu',
              'Malay',
              'Turkish',
              'Korean',
              'French',
              'German',
              'Vietnamese',
              'Tamil',
              'Urdu',
            ]}
            {...register('social_history.language')}
          />
        </RowContent>
        <RowContent title="Occupation">
          <Form.MuiTextField
            disabled={disabled}
            placeholder="Enter"
            {...register('social_history.job')}
          />
        </RowContent>
        <RowContent title="Religion">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('social_history.religion.value')}
              onChange={v => setValue('social_history.religion.value', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('social_history.religion.input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </RowContainer>
  );
};

export default SocialHistory;
