import { Grid, Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { SBARTable } from 'routes/Main/style';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const table = [
  {
    label_1: 'WBC',
    key1: 'assessment_lap_0',
    label_2: 'K',
    key2: 'assessment_lap_1',
    label_3: 'CKMB',
    key3: 'assessment_lap_2',
  },
  {
    label_1: 'Hgb',
    key1: 'assessment_lap_3',
    label_2: 'INR',
    key2: 'assessment_lap_4',
    label_3: 'BNP',
    key3: 'assessment_lap_5',
  },
  {
    label_1: 'NA',
    key1: 'assessment_lap_6',
    label_2: 'Anti-Xa',
    key2: 'assessment_lap_7',
    label_3: 'Trop',
    key3: 'assessment_lap_8',
  },
  {
    label_1: 'Pit',
    key1: 'assessment_lap_9',
    label_2: 'Cr',
    key2: 'assessment_lap_10',
    label_3: 'Lipase',
    key3: 'assessment_lap_11',
  },
  {
    label_1: 'NH',
    key1: 'assessment_lap_12',
    label_2: 'LETs',
    key2: 'assessment_lap_13',
    label_3: '',
    key3: 'wdqw',
  },
];

const Assessment5 = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <RowContainer
        xs={12}
        sx={{ margin: '0px 0px 30px 0px', marginLeft: '-25px' }}
      >
        <RowContent title="Labs" titleRatio={1.2} childrenRatio={10.8}>
          {table.map((item, i) => {
            if (i === 4) {
              return (
                <Grid item xs={12} key={i}>
                  <SBARTable>
                    <Typography sx={{ padding: '6px', fontSize: '12px' }}>
                      {item.label_1}
                      <sub>3</sub>
                    </Typography>
                  </SBARTable>
                  <SBARTable>
                    <input
                      disabled={disabled}
                      style={{
                        border: 'none',
                        width: '100%',
                        height: '44px',
                      }}
                      {...register(`${item.key1}`)}
                    />
                  </SBARTable>
                  <SBARTable>
                    <Typography sx={{ padding: '6px', fontSize: '12px' }}>
                      {item.label_2}
                    </Typography>
                  </SBARTable>
                  <SBARTable>
                    <input
                      disabled={disabled}
                      style={{
                        border: 'none',
                        width: '100%',
                        height: '44px',
                      }}
                      {...register(`${item.key2}`)}
                    />
                  </SBARTable>
                  <SBARTable>
                    <Typography
                      sx={{
                        padding: '6px',
                        fontSize: '12px',
                        whiteSpace: 'pre',
                      }}
                    >
                      {'   '}
                    </Typography>
                  </SBARTable>
                  <SBARTable>
                    <input
                      disabled={true}
                      style={{
                        border: 'none',
                        width: '100%',
                        height: '44px',
                      }}
                    />
                  </SBARTable>
                </Grid>
              );
            }
            return (
              <Grid item xs={12} key={i}>
                <SBARTable>
                  <Typography sx={{ padding: '6px', fontSize: '12px' }}>
                    {item.label_1}
                  </Typography>
                </SBARTable>
                <SBARTable>
                  <input
                    disabled={disabled}
                    style={{
                      border: 'none',
                      width: '100%',
                      height: '44px',
                    }}
                    {...register(`${item.key1}`)}
                  />
                </SBARTable>
                <SBARTable>
                  <Typography sx={{ padding: '6px', fontSize: '12px' }}>
                    {item.label_2}
                  </Typography>
                </SBARTable>
                <SBARTable>
                  <input
                    disabled={disabled}
                    style={{
                      border: 'none',
                      width: '100%',
                      height: '44px',
                    }}
                    {...register(`${item.key2}`)}
                  />
                </SBARTable>
                <SBARTable>
                  <Typography sx={{ padding: '6px', fontSize: '12px' }}>
                    {item.label_3}
                  </Typography>
                </SBARTable>
                <SBARTable>
                  <input
                    disabled={disabled}
                    style={{
                      border: 'none',
                      width: '100%',
                      height: '44px',
                    }}
                    {...register(`${item.key3}`)}
                  />
                </SBARTable>
              </Grid>
            );
          })}
        </RowContent>
      </RowContainer>
    </>
  );
};

export default Assessment5;
