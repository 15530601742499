import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SubRowContent from './RowContent';
import SectionTitle from '../../components/SectionTitle';

import { Stack } from '@mui/material';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const BasicInformation = (props: Props) => {
  const { disabled, register, watch, setValue, getValues } = props;

  const contents = [
    {
      label: 'Occupation',
      element: (
        <Form.MuiTextField
          {...register('basic_information.occupation')}
          required={false}
          disabled={disabled}
          placeholder="Enter"
        />
      ),
    },
    {
      label: 'Insurance\nType',
      element: (
        <Form.MuiRadioGroup
          i18nKey="HOME_CARE.INSURANCE_TYPE"
          values={[1, 2, 3]}
          disabled={disabled}
          defaultValue={getValues('basic_information.insurance_type')}
          onChange={v => setValue('basic_information.insurance_type', v)}
        />
      ),
    },
    {
      label: 'Residence',
      element: (
        <Stack direction="row" spacing={1}>
          <Form.MuiRadioGroup
            i18nKey="HOME_CARE.RESIDENCE"
            values={[1, 2, 3]}
            disabled={disabled}
            defaultValue={getValues('basic_information.residence')}
            onChange={v => setValue('basic_information.residence', v)}
            width="auto"
          />
          <Form.MuiTextField
            {...register('basic_information.residence_etc')}
            required={false}
            disabled={disabled}
            placeholder="Etc"
          />
        </Stack>
      ),
    },
    {
      label: 'Religion',
      element: (
        <Form.MuiTextField
          {...register('basic_information.religion')}
          required={false}
          disabled={disabled}
          placeholder="Enter"
        />
      ),
    },
    {
      label: 'Nursing\nProvider',
      element: (
        <Form.MuiTextField
          {...register('basic_information.nursing_provider')}
          required={false}
          disabled={disabled}
          placeholder="Enter"
        />
      ),
    },
    {
      label: 'Sanitisation',
      element: (
        <Stack direction="row" spacing={1}>
          <Form.MuiRadioGroup
            i18nKey="HOME_CARE.SANITARY"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('basic_information.sanitary')}
            onChange={v => setValue('basic_information.sanitary', v)}
            width="auto"
          />
          <Form.MuiTextField
            {...register('basic_information.sanitary_notes')}
            required={false}
            disabled={disabled}
            placeholder="Enter"
          />
        </Stack>
      ),
    },
    {
      label: 'Marital\nStatus',
      element: (
        <Form.MuiTextField
          {...register('basic_information.marital_status')}
          required={false}
          disabled={disabled}
          placeholder="Enter"
        />
      ),
    },
    {
      label: 'Past\nHistory',
      element: (
        <Form.MuiTextField
          {...register('basic_information.past_history')}
          required={false}
          disabled={disabled}
          placeholder="Enter"
        />
      ),
    },
    {
      label: 'Safety',
      element: (
        <Stack direction="row" spacing={1}>
          <Form.MuiRadioGroup
            i18nKey="HOME_CARE.SAFETY"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('basic_information.safety')}
            onChange={v => setValue('basic_information.safety', v)}
            width="auto"
          />
          <Form.MuiTextField
            {...register('basic_information.safety_notes')}
            required={false}
            disabled={disabled}
            placeholder="Enter"
          />
        </Stack>
      ),
    },
  ];

  return (
    <>
      <SectionTitle title="Basic Information" />
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => {
          if (label === 'Insurance\nType') {
            return (
              <>
                <SubRowContent
                  title={label}
                  titleRatio={0.8}
                  childrenRatio={3.1}
                >
                  {element}
                </SubRowContent>
              </>
            );
          } else if (label === 'Nursing\nProvider') {
            return (
              <>
                <SubRowContent
                  title={label}
                  titleRatio={0.8}
                  childrenRatio={3.1}
                >
                  {element}
                </SubRowContent>
              </>
            );
          } else if (label === 'Marital\nStatus') {
            return (
              <>
                <SubRowContent
                  title={label}
                  titleRatio={0.8}
                  childrenRatio={3.1}
                >
                  {element}
                </SubRowContent>
              </>
            );
          } else if (label === 'Past\nHistory') {
            return (
              <>
                <SubRowContent
                  title={label}
                  titleRatio={0.8}
                  childrenRatio={3.1}
                >
                  {element}
                </SubRowContent>
              </>
            );
          }
          return (
            <>
              <RowContent title={label} titleRatio={0.8} childrenRatio={3.1}>
                {element}
              </RowContent>
            </>
          );
        })}
      </RowContainer>
    </>
  );
};

export default BasicInformation;
