import Form from 'components/Form';
import { Stack } from '@mui/material';

import { useState } from 'react';

import { IFormValues, IFormRegister, IFormWatch } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import RowContentPreWrap from './components/RowContentPreWrap';
import SectionTitle from '../components/SectionTitle';

import MuiRadioGroup from './components/RadioConsequence';

import { MobileTimePicker } from '@mui/x-date-pickers';
import MuiTextField from 'components/Form/MuiTextField';
import { AccessTime } from '@mui/icons-material';

interface Props extends IFormValues, IFormRegister, IFormWatch {
  disabled?: boolean;
}

const ConsequencesDetails = (props: Props) => {
  const { disabled, getValues, setValue, register, watch } = props;

  const [classification, setClassification] = useState(
    getValues('accident_consequences_details.accident_classification')
      ? getValues('accident_consequences_details.accident_classification')
      : -1
  );

  return (
    <>
      <SectionTitle title="Incident Details" />
      <RowContainer xs={12}>
        <RowContent
          title="Date of the Incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <Form.MuiTextField
            type="date"
            required={false}
            disabled={disabled}
            sx={{ width: '170px' }}
            {...register('accident_consequences_details.accidence_date')}
          />
        </RowContent>
        <RowContent
          title="Time of the Incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <MobileTimePicker
            value={
              watch('accident_consequences_details.discovery_date') || null
            }
            onChange={v =>
              setValue('accident_consequences_details.discovery_date', v)
            }
            renderInput={params => (
              <MuiTextField
                {...params}
                required={false}
                placeholder="00:00"
                sx={{ width: '170px' }}
                InputProps={{ endAdornment: <AccessTime /> }}
              />
            )}
          />
        </RowContent>
        <RowContentPreWrap
          title="Location of the 
          Incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <MuiRadioGroup
            i18nKey="SAFETY.DISCOVERY.PLACE"
            values={[1, 2, 3, 4, 0]}
            disabled={disabled}
            defaultValue={getValues(
              'accident_consequences_details.discovery_place'
            )}
            onChange={v =>
              setValue('accident_consequences_details.discovery_place', v)
            }
            width="auto"
          />
          <Form.MuiTextField
            required={false}
            fullWidth={false}
            disabled={disabled}
            placeholder="Direct input"
            sx={{ marginLeft: '10px' }}
            {...register('accident_consequences_details.discovery_place_etc')}
          />
        </RowContentPreWrap>

        <RowContent
          title="Nature of the Incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="SAFETY.ACCIDENT.CLASSIFICATION"
              defaultValue={classification}
              value={classification}
              values={[1, 2, 3, 4, 5]}
              disabled={disabled}
              onChange={v => {
                setValue(
                  'accident_consequences_details.accident_classification',
                  v
                );
                setClassification(v);
              }}
              width="auto"
            />
          </Stack>
        </RowContent>
        <RowContent titleRatio={1.5} childrenRatio={10.5}>
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="SAFETY.ACCIDENT.CLASSIFICATION"
              defaultValue={classification}
              value={classification}
              values={[0]}
              disabled={disabled}
              onChange={v => {
                setValue(
                  'accident_consequences_details.accident_classification',
                  v
                );
                setClassification(v);
              }}
              width="auto"
            />
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              sx={{ marginLeft: '10px' }}
              {...register(
                'accident_consequences_details.accident_classification_etc'
              )}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default ConsequencesDetails;
