import Form from 'components/Form';
import { Fragment, useState, useEffect } from 'react';
import { IPatientInfo } from 'apis/admin/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';
import {
  Button,
  IconButton,
  Stack,
  FormHelperText,
  Table,
  Typography,
  TableBody,
  TableCell,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import { Ti18nId } from 'hooks/useI18n';
import { IInpomation } from 'apis/survey/type';
import useInfoEtc from 'store/patientEtc/useinfoEtc';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import RowContentInfo from './components/RowContentInfo';
import MuiTable from './MuiTable';
import MuiTextField from 'components/Form/MuiTextField';
/* import { validateHeaderValue } from 'http'; */

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
  nurseName: string;
  patientInfo: IPatientInfo;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const PatientInfo = (props: Props) => {
  const {
    disabled,
    nurseName,
    patientInfo,
    register,
    watch,
    onRequired,
    onSuccess,
    setValue,
  } = props;

  return (
    <RowContainer xs={12}>
      <RowContent title="Patient's Name" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={patientInfo.name}
          InputProps={{ readOnly: true }}
        />
      </RowContent>
      <RowContent title="Registration No." titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={patientInfo.patient_id}
          InputProps={{ readOnly: true }}
        />
      </RowContent>
      <RowContent title="Age" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={patientInfo.age}
          InputProps={{ readOnly: true }}
        />
      </RowContent>
      <RowContent title="Gender" titleRatio={1} childrenRatio={2}>
        <Form.MuiRadioGroup
          i18nKey="GENDER"
          values={[1, 2]}
          value={patientInfo.gender}
          defaultValue={patientInfo.gender}
        />
      </RowContent>
      <RowContent title="Department" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={patientInfo.department}
          InputProps={{ readOnly: true }}
        />
      </RowContent>
      <RowContent title="Main Doctor" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          value={patientInfo.main_doctor}
          InputProps={{ readOnly: true }}
        />
      </RowContent>
      <RowContent title="Recorder" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField value={nurseName} InputProps={{ readOnly: true }} />
      </RowContent>
      <RowContentInfo
        title="Information 
Provider"
        titleRatio={1}
        childrenRatio={2}
      >
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Direct input"
          {...register('offer')}
        />
      </RowContentInfo>
      <Table sx={{ marginLeft: '16px' }}>
        <TableBody>
          <TableCell></TableCell>
        </TableBody>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
            marginLeft: '16px',
            marginTop: '16px',
          }}
        >
          Guardian
        </Typography>
      </Table>
      <RowContent title="Guardian 1 name" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register('contact1.0.contact')}
        />
      </RowContent>
      <RowContent title="Relationship" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact1.0.name`)}
        />
      </RowContent>
      <RowContent title="Contact" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact1.0.relation`)}
        />
      </RowContent>
      <RowContent title="Etc." titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="ex. Treatment directio"
          {...register(`contact1.0.etc`)}
        />
      </RowContent>

      <RowContent title="Guardian 2 name" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact2.0.contact`)}
        />
      </RowContent>
      <RowContent title="Relationship" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact2.0.name`)}
        />
      </RowContent>
      <RowContent title="Contact" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact2.0.relation`)}
        />
      </RowContent>
      <RowContent title="Etc." titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="ex. Treatment directio"
          {...register(`contact2.0.etc`)}
        />
      </RowContent>

      <RowContent title="Guardian 3 name" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact3.0.contact`)}
        />
      </RowContent>
      <RowContent title="Relationship" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact3.0.name`)}
        />
      </RowContent>
      <RowContent title="Contact" titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="Enter"
          {...register(`contact3.0.relation`)}
        />
      </RowContent>
      <RowContent title="Etc." titleRatio={1} childrenRatio={2}>
        <Form.MuiTextField
          required={false}
          fullWidth={false}
          disabled={disabled}
          placeholder="ex. Treatment directio"
          {...register(`contact3.0.etc`)}
        />
      </RowContent>
    </RowContainer>
  );
};

export default PatientInfo;
