import { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import {
  CPRStyledTableCell,
  CPRStyledTableCellFirst,
  CPRStyledTableCellHead,
  CPRStyledTableCellHeadNumbering,
  CPRStyledTableCellBodyNumbering,
} from 'routes/Main/style';

import { IFormValues, IFormWatch, IFormRegister } from 'routes/Main/type';
import CPRHeader from './CPRHeader';
import { initialCPR } from '../../initialStates';

const radioId = ['face', 'activity', 'respiratory', 'vocalization'];
const contentLabel = [
  {
    id: 'Clinical\nObservation',
    ko: [
      'BP (mmHg)',
      'HR (per/min)',
      'RR (per/min)',
      'BT (°C)',
      'SpO2(%)',
      'LOC',
      'Pupil size',
      'Pupillary reflex',
      'Heart rhythm\n(VF, VT, PEA,\nAsystole, ROSC etc)',
    ],
    idForApi: 'clinical_observation',
    koForApi: [
      'bp',
      'hr',
      'rr',
      'bt',
      'spo2',
      'consciousness',
      'pupil_size',
      'pupil_reflex',
      'cardio_ryt',
    ],
    desc: Array(11).fill(' '),
  },
  {
    id: 'Care',
    ko: ['Chest compression', 'Artificial ventilation', 'Defibrillation'],
    idForApi: 'treatment',
    koForApi: ['chest_compression', 'artificial_ventilation', 'aed'],
    desc: Array(11).fill(' '),
  },
  {
    id: 'Endotracheal\nIntubation',
    ko: ['ID', 'Depth', 'Balloon', 'Operator'],
    idForApi: 'intubation',
    koForApi: ['id', 'depth', 'balloon', 'practitioner'],
    desc: Array(11).fill(' '),
  },
  {
    id: 'Inject',
    ko: [' ', ' ', ' ', ' ', ' ', ' ', '  '],
    idForApi: 'medication',
    koForApi: ['no', 'no', 'no', 'no', 'no', 'no', 'no'],
    desc: Array(11).fill(' '),
  },
  {
    id: 'Inspection',
    ko: ['ABGA', 'Chest X-ray', 'lab'],
    idForApi: 'test',
    koForApi: ['abga', 'chest', 'lab'],
    desc: Array(11).fill(' '),
  },
];

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  timeStart: number;
}

const CPRContents = (props: Props) => {
  const { disabled, setValue, getValues, register, timeStart } = props;

  const [cprRecord, setCprRecord] = useState(initialCPR);

  const timeCount = new Array(11).fill(timeStart).map((num, i) => num + i);

  useEffect(() => {
    if (getValues('update_at')) {
      setCprRecord(prev => ({ ...prev, update_at: getValues('update_at') }));
    } else {
      setValue('update_at', cprRecord.update_at);
    }
    if (getValues('find_date')) {
      setCprRecord(prev => ({ ...prev, find_date: getValues('find_date') }));
    } else {
      setValue('find_date', cprRecord.find_date);
    }
    if (getValues('find_time')) {
      setCprRecord(prev => ({ ...prev, find_time: getValues('find_time') }));
    } else {
      setValue('find_time', cprRecord.find_time);
    }
    if (getValues('terminate_reason')) {
      setCprRecord(prev => ({
        ...prev,
        terminate_reason: getValues('terminate_reason'),
      }));
    } else {
      setValue('terminate_reason', cprRecord.terminate_reason);
    }
    if (getValues('clinical_observation')) {
      setCprRecord(prev => ({
        ...prev,
        clinical_observation: getValues('clinical_observation'),
      }));
    } else {
      setValue('clinical_observation', cprRecord.clinical_observation);
    }
    if (getValues('treatment')) {
      setCprRecord(prev => ({ ...prev, treatment: getValues('treatment') }));
    } else {
      setValue('treatment', cprRecord.treatment);
    }
    if (getValues('intubation')) {
      setCprRecord(prev => ({
        ...prev,
        intubation: getValues('intubation'),
      }));
    } else {
      setValue('intubation', cprRecord.intubation);
    }
    if (getValues('medication')) {
      setCprRecord(prev => ({
        ...prev,
        medication: getValues('medication'),
      }));
    } else {
      setValue('medication', cprRecord.medication);
    }
    if (getValues('test')) {
      setCprRecord(prev => ({ ...prev, test: getValues('test') }));
    } else {
      setValue('test', cprRecord.test);
    }
  }, []);

  useEffect(() => {
    // console.log(cprRecord);
  }, [cprRecord]);

  const headerProps = {
    disabled,
    register,
    getValues,
    setValue,
    cprRecord,
    setCprRecord,
  };

  return (
    <>
      {timeStart === 0 && <CPRHeader {...headerProps} />}
      <Box
        sx={{
          width: '88%',
          marginTop: '30px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <CPRStyledTableCellHead
                colSpan={1}
                align="center"
              ></CPRStyledTableCellHead>
              <CPRStyledTableCellHead colSpan={1} align="center">
                time (min)
              </CPRStyledTableCellHead>

              {timeCount.map(min => (
                <CPRStyledTableCellHeadNumbering
                  colSpan={1}
                  align="right"
                  sx={{ paddingRight: '5px' }}
                  key={min}
                >
                  {min}
                </CPRStyledTableCellHeadNumbering>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {contentLabel.map(
              (
                content: {
                  id: string;
                  ko: string[];
                  desc: string[];
                  koForApi: string[];
                  idForApi: string;
                },
                i
              ) => {
                return (
                  <TableRow>
                    <CPRStyledTableCellFirst
                      align="center"
                      sx={{
                        minWidth: '30px',
                        whiteSpace: 'pre',
                        height: '44px',
                      }}
                    >
                      {content.id}
                    </CPRStyledTableCellFirst>
                    <CPRStyledTableCell>
                      {content.ko.map((el, i) => {
                        if (el === ' ' || el === '  ') {
                          console.log(`medication.no00_${timeStart + i + 1}`);

                          return (
                            <TableRow
                              sx={{
                                lineHeight: el.includes('Heart rhythm')
                                  ? '15px'
                                  : '43.5px',
                                borderBottom:
                                  i !== content.ko.length - 1
                                    ? '1px solid lightgray'
                                    : '',
                              }}
                            >
                              <Box
                                sx={{
                                  minWidth: '60px',
                                  textAlign: 'center',
                                }}
                              >
                                <input
                                  disabled={disabled}
                                  style={{
                                    border: 'none',
                                    width: '100%',
                                    height: '44px',
                                  }}
                                  {...register(
                                    `medication.no00_${timeStart + i + 1}`
                                  )}
                                  onChange={e =>
                                    setCprRecord(prev => ({
                                      ...prev,
                                      [`medication.no00_${timeStart + i + 1}`]:
                                        e.target.value,
                                    }))
                                  }
                                />
                              </Box>
                            </TableRow>
                          );
                        }
                        return (
                          <TableRow
                            sx={{
                              lineHeight: content.ko[i].includes('Heart rhythm')
                                ? '15px'
                                : '44px',
                              borderBottom:
                                i !== content.ko.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{
                                width: '140px',
                                whiteSpace: 'pre',
                                padding: '0 0 0 5px',
                              }}
                            >
                              {content.ko[i]}
                            </Box>
                          </TableRow>
                        );
                      })}
                    </CPRStyledTableCell>

                    {content.desc.map((_, descIdx) => {
                      return (
                        <CPRStyledTableCellBodyNumbering>
                          {content.ko.map((_, koIdx) => {
                            // console.log('content.ko[i]', content.ko[koIdx]);
                            if (
                              content.ko[koIdx] === 'ABGA' ||
                              content.ko[koIdx] === 'Chest X-ray' ||
                              content.ko[koIdx] === 'lab'
                            ) {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43.5px',
                                    borderBottom:
                                      content.ko[koIdx] !== 'lab'
                                        ? '1px solid lightgray'
                                        : 'none',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'right',
                                    }}
                                  >
                                    <Checkbox
                                      size="small"
                                      {...register(
                                        `${content.idForApi}.${
                                          content.koForApi[koIdx]
                                        }${descIdx + timeStart}`
                                      )}
                                      checked={
                                        // @ts-ignore
                                        cprRecord.test[
                                          `${content.koForApi[koIdx]}${
                                            descIdx + timeStart
                                          }`
                                        ] === true
                                      }
                                      onChange={(_, checked) => {
                                        setValue(
                                          `${content.idForApi}.${
                                            content.koForApi[koIdx]
                                          }${descIdx + timeStart}`,
                                          checked
                                        );
                                        setCprRecord(prev => {
                                          const key = `${
                                            content.koForApi[koIdx]
                                          }${descIdx + timeStart}`;
                                          const idForApi =
                                            content.idForApi as keyof typeof prev;
                                          return {
                                            ...prev,
                                            [idForApi]: {
                                              ...(prev[idForApi] as object),
                                              [key]: checked,
                                            },
                                          };
                                        });
                                      }}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            }

                            return (
                              <TableRow
                                sx={{
                                  lineHeight:
                                    content.ko[koIdx].includes(
                                      'Heart rhythm'
                                    ) ||
                                    content.ko[koIdx].includes(
                                      'Defibrillation'
                                    ) ||
                                    content.ko[koIdx].includes('Operator') ||
                                    content.ko[koIdx] === '  ' ||
                                    content.ko[koIdx].includes('lab')
                                      ? '44px'
                                      : '43.3px',
                                  borderBottom:
                                    content.ko[koIdx].includes(
                                      'Heart rhythm'
                                    ) ||
                                    content.ko[koIdx].includes(
                                      'Defibrillation'
                                    ) ||
                                    content.ko[koIdx].includes('Operator') ||
                                    content.ko[koIdx] === '  ' ||
                                    content.ko[koIdx].includes('lab')
                                      ? 'none'
                                      : '1px solid lightgray',
                                }}
                              >
                                <Box
                                  sx={{
                                    minWidth: '60px',
                                    textAlign: 'center',
                                  }}
                                >
                                  <input
                                    disabled={disabled}
                                    style={{
                                      border: 'none',
                                      width: '100%',
                                      height: '44px',
                                    }}
                                    {...register(
                                      content.idForApi === 'medication'
                                        ? `medication.no${
                                            descIdx + timeStart
                                          }_${koIdx + 1}`
                                        : `${content.idForApi}.${
                                            content.koForApi[koIdx]
                                          }${descIdx + timeStart}`
                                    )}
                                    value={
                                      content.idForApi === 'medication'
                                        ? // @ts-ignore
                                          cprRecord.medication[
                                            `no${descIdx + timeStart}_${
                                              koIdx + 1
                                            }`
                                          ]
                                        : // @ts-ignore
                                          cprRecord[content.idForApi][
                                            `${content.koForApi[koIdx]}${
                                              descIdx + timeStart
                                            }`
                                          ]
                                      // 어느칸에 무슨값들어갔는지 보기위함
                                      // `${content.koForApi[koIdx]}${
                                      //   descIdx + timeStart
                                      // }`
                                    }
                                    onChange={e => {
                                      if (content.idForApi === 'medication') {
                                        const key = `no${descIdx}_${koIdx + 1}`;
                                        setCprRecord(prev => {
                                          return {
                                            ...prev,
                                            medication: {
                                              // @ts-ignore
                                              ...prev[cprRecord.medication],
                                              [key]: e.target.value,
                                            },
                                          };
                                        });
                                      } else {
                                        const key = `${
                                          content.koForApi[koIdx]
                                        }${descIdx + timeStart}`;
                                        setCprRecord(prev => {
                                          const idForApi =
                                            content.idForApi as keyof typeof prev;
                                          return {
                                            ...prev,
                                            [idForApi]: {
                                              ...(prev[idForApi] as object),
                                              [key]: e.target.value,
                                            },
                                          };
                                        });
                                      }
                                    }}
                                  />
                                </Box>
                              </TableRow>
                            );
                          })}
                        </CPRStyledTableCellBodyNumbering>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default CPRContents;
