import MuiDialog from 'components/MuiDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const TermsOfService = ({ isOpen, onClose }: Props) => (
  <MuiDialog.Confirm
    maxWidth="md"
    isOpen={isOpen}
    title="Accept the terms and conditions of the SmartNurse service"
    message={`
      Article 1 The Purpose of these Terms and Conditions of Use is to prescribe all matters concerning the conditions and operation of the service of "SmartNurse ENR" (after this referred to as "Site"). 

      Article 2
      Terminology Definitions Key terms used in these Terms and Conditions are defined as follows:
      ① Member: A person who agrees to the terms and conditions of the site, registers as a member by providing personal information and refers to a user who enters into a contract with the site and uses the site.
      ② Contract for use: It refers to a contract between a site and a member in connection with the use of the site.
      ③ Member ID (after this referred to as "ID"): It relates to a combination of unique characters and numbers assigned to each member for the identification of members and the use of members' services.
      ④ Password: It refers to a combination of letters and numbers selected by a member to confirm that he/she is a member who matches the ID given to him/her and to protect the rights and interests of the member.
      ⑤ Operator: It refers to an operator who opens and operates a website on the service.
      ⑥ Cancellation: It refers to the cancellation of a contract of use by a member. The operator of the rules other than the Article 3 Terms and Conditions may notify the operation policy separately if necessary. If the terms and conditions overlap, the operation policy will be applied first.

      Article 3 Regulations other than the terms and conditions
      The operator may notify the operation policy separately if necessary, and if these terms and conditions overlap with the operating policy, the operating policy takes precedence.
      
      Article 4
      Conclusion of a contract for use
      ① The use contract is established with the consent of the operator to the contents of these terms and conditions of the person who intends to register as a member and use the site.
      ② Those who wish to register as a member and use the service will express their consent to this agreement by reading this agreement and selecting "I agree" below when applying for the site.

      Article 5
      Application for service use
      ① Users who want to register as members and use the site must provide all the information requested by the site (user ID, password, nickname, etc.).
      ② Members who have not registered their true information, such as stealing other people's information or registering false information, cannot claim any rights in connection with the use of the site and can be punished according to relevant laws and regulations.

      Article 6
      Personal information processing policy
      The site and operator do not have passwords for the personal information provided when signing up for membership, and related parts follow the website's personal information processing policy.
      The operator shall endeavor to protect the personal information of members, including member registration information, as prescribed by the relevant statutes.
      The protection of members' personal information is in accordance with the relevant laws and regulations and the website's personal information processing policy.
      However, the operator is not responsible for any information exposed due to reasons attributable to the members.
      If a member registers and distributes illegal posts, such as posts that are hindered by customs and customs or violate national security, the operator may view the member's data and submit the data to the relevant agency at the request of the appropriate agency.


      Article 7
      Operator's Obligations
      ① If the operator deems that opinions or complaints raised by the user members are justified, he/she shall deal with them as soon as possible. However, if it is difficult to process quickly due to personal reasons, we will do our best by sending a notice or a note, or an e-mail to the members afterward.
      ② The operator may require the site to repair or repair any facility failure or loss without delay to provide a continuous and stable site.
      However, if there is a natural disaster or unavoidable reason for the site or operator, the site's operation may be temporarily suspended.

      Article 8
      membership obligations
      ① Members shall comply with the Site's announcements and related laws and regulations, such as the provisions of these Terms and Conditions, all restrictions, notices, and operating policies, and shall not interfere with the Site's business or damage the Site's reputation.
      ② Members shall not transfer or gift the right to use the service or other status under the contract of use to others without the explicit consent of the site and shall not provide it as collateral.
      ③ Customers must pay considerable attention to ID and password management and cannot provide IDs to third parties without the consent of the operator or site.
      ④ Members shall not infringe on the intellectual property rights of operators, sites, and third parties.

      Article 9 Service Hours
      ① In principle, the service hours are 24 hours a day, seven days a year, unless there is a unique obstacle to work or technology.
      However, the site can temporarily suspend the service on the day or time set by the site for regular system inspection, expansion, and replacement, and the suspension of the service due to scheduled work will be announced on the site's website in advance, so please refer to it from time to time.
      ② However, the site may suspend the service temporarily or permanently without prior notice or notice in the following cases:
      - In the event of an urgent system inspection, extension, replacement, failure, or malfunction.
      - Where there are unavoidable reasons, such as a national emergency, a power outage, a natural disaster, etc.;
      - Where a key telecommunications service provider prescribed in the Telecommunications Business Act suspends telecommunications services;
      - Where there is an obstacle to the regular use of services due to the congestion of service use, etc.;
      ③ In the case of service interruption under the preceding paragraph, the site shall notify the members in advance through a notice, etc. However, if it is impossible to announce in advance the interruption of services caused by reasons beyond the site's control, it shall be replaced by a post-announcement.

      Article 10
      Cancellation of service use
      ① If a member intends to terminate the use contract with the site, he/she must apply for online registration cancellation. On the other hand, you have to cancel the use contract for the site separately from the cancellation of the site.
      ② The site-related programs provided by the site are automatically deleted from the membership management screen at the same time as the cancellation application is applied, so the operator can no longer view the information of the cancellation applicant.

      Article 11
      Restrictions on service use
      A member shall not engage in any of the following acts, and in the event of such acts, the site may restrict the use of the member's services, take legal action, terminate the use contract, or suspend the service for a fixed period.
      ① Registration of false information when registering as a member or changing information after signing up.
      ② Obstructing other people's use of the site or stealing information.
      ③ Pretending to be the operator, employee, or interested person of the site
      ④ infringement of the personal or intellectual property rights of a site or other third party or obstruction of business;
      ⑤ Fraudulent use of another member's ID
      ⑥ Collection, storage, and disclosure of personal information about other members without their consent;
      ⑦ an act that is objectively judged to be linked to a crime
      ⑧ Other acts that violate relevant laws and regulations

      Article 12 Management of Posts
      ① The responsibility for managing and operating the site's posts and materials lies with the operator. The operator shall continually monitor defective posts and materials. When they find or receive a report on faulty posts and materials, he/she shall delete the posts and materials and warn the members who registered them. On the other hand, the publisher is responsible for the posts posted by the members, so the members themselves should not publish posts that violate these terms and conditions.
      ② If there is a request for correction by a public institution such as the Information and Communication Ethics Committee, the operator can delete or move the post without the prior consent of the member.
      ③ The criteria for determining defective posts are as follows.
      - In the event of severe insult or injury to another member or a third party;
      - In the case of disseminating or linking information that violates public order and customs;
      - If the content encourages illegal copying or hacking
      - In the case of commercials for profit,
      - Where it is objectively recognized that it is related to a crime;
      - In the event of an infringement of other rights, such as copyright, with other users or third parties;
      - Where it is deemed to violate other relevant laws and regulations;
      ④ Sites and operators may temporarily suspend posting (suspend transmission) if they receive a request from a third party for defamation or infringement of intellectual property rights and if a lawsuit, agreement, or similar decision is made between the requestor and the registrar.

      Article 13 Archive of Posts
      If the site operator is forced to suspend the site due to unavoidable circumstances, we will notify the members in advance and make every effort to make it easier to transfer the post.

      Article 14 Copyright in Posts
      ① The copyright of a post posted by a member on the site belongs to the member who posted it. In addition, the site cannot use the post commercially without the publisher's consent. However, this is not the case for non-profit purposes, and you also have the right to publish within the service.
      ② Members shall not use commercial materials published in the service, such as arbitrarily processing or selling information acquired using the service.
      ③ Article 12 If it is deemed to fall under each subparagraph, it may be deleted, moved, or registered without prior notice.

      Article 15 compensation for damages
      ① All civil and criminal liability arising from this site is primarily the responsibility of the members themselves.
      ② If the damage received by a member from this site is forceful, such as a natural disaster, or due to the intention or negligence of the member, compensation for damages shall not be made.

      Article 16 Immunity
      ① The operator is exempted from liability for damages caused by the member's failure to obtain the expected profit from the site's service provision or the selection or use of service materials.
      ② The operator is exempted from liability in the event of a failure of the service base of this site and telecommunications services provided by other carriers, and damages related to the service base of this site are in accordance with the terms and conditions of use of the site.
      ③ The operator is not responsible for any material stored, published, or transmitted by the member.
      ④ The operator shall not be responsible for any disturbance in the use of the service due to reasons attributable to the members.
      ⑤ The operator shall not be responsible for any activities (including data transmission and other community activities) between or between the members and third parties, whether or not they are in or out of this service.
      ⑥ The operator is not responsible for the authenticity, reliability, accuracy, etc., of the materials posted or transmitted by the member and all materials that the member may receive to this site.
      ⑦ The operator shall not be liable for any damages arising from that place in the case of goods transactions, etc., between members or between members and third parties through services.
      ⑧ The operator shall not be responsible for any disputes arising between members or between members and third parties without reasons attributable to the operator.
      ⑨ The operator shall not be responsible for damage to the member due to system failure, system failure due to intentional or intentional negligence, computer virus not developed by renowned domestic and foreign research institutes or security-related companies, or other force majeure reasons beyond the operator's control. Supplementary Provisions These terms and conditions will take effect on August 15, 2021.
    `}
    onClose={onClose}
  />
);

export default TermsOfService;
