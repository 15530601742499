import Form from 'components/Form';

import { Box, Grid, Typography } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'Uremic symptoms such as nausea, vomiting, loss of appetite, etc',
    key: 'no3_1.checked1',
  },
  {
    label: 'Deterioration of mental conditions and encephalopathy',
    key: 'no3_1.checked2',
  },
  {
    label: 'Pericarditis',
    key: 'no3_1.checked3',
  },
  {
    label: 'Bleeding tendency',
    key: 'no3_1.checked4',
  },
  {
    label:
      'When excessive fluid such as pulmonary edema is not controlled by drug administration',
    key: 'no3_1.checked5',
  },
  {
    label: 'Metabolic acidosis intensifies in patients with oliguria or anuria',
    key: 'no3_1.checked6',
  },
];

const info = [
  { contents: 'Anticoagulant-induced bleeding tendency' },
  { contents: 'Hypotension' },
  { contents: 'Hypothermia' },
  { contents: 'Electrolyte and acid-base imbalance' },
];

const RecordInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="3. Procedure Narratives" />
      <Box sx={{ margin: '48px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
            1) Purpose and effectiveness of the procedure
          </Typography>
          {checks.map((v, i) => {
            return (
              <Grid item xs={11.5}>
                <Form.MuiCheckbox
                  label={v.label}
                  disabled={disabled}
                  defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                  onChange={(_, checked) => {
                    setValue(v.key, checked);
                  }}
                />
              </Grid>
            );
          })}
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, marginTop: '10px' }}
          >
            2) Possible side effects
          </Typography>
          {info.map(a => {
            return (
              <Grid item xs={11.5}>
                <Typography sx={{ fontSize: '14px', lineHeight: '30px' }}>
                  • {a.contents}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Typography
          sx={{ margin: '30px 0 20px 0', fontSize: '14px', fontWeight: 500 }}
        >
          The principal/guardian has heard and understood the above full
          explanation for continuous renal replacement therapy and agrees to
          ensure the patient's safety and treatment.
        </Typography>
      </Box>
    </>
  );
};

export default RecordInfo;
