import { IPatientInfo } from 'apis/admin/type';
import { SurveyFormProps } from 'components/MuiDialog/SurveyForm';
import {
  initialECardex,
  initialTakingOver,
  initialOutHospitalSurvey,
  initialHospitalizationSurvey,
  initialMedicationSurvey,
  initialRadiology,
  initialPathology,
  initialBedScore,
  initialFall,
  initialPediatric_fall,
  initialGCS,
  initialPediatric_GCS,
  initialFourScore,
  initialSafety,
  initialNeeds,
  initialClinicalObservation,
  initialGlucose,
  initialNRS,
  initialFLACC,
  initialCNPS,
  initialFFI,
  initialKOOS,
  initialLEFS,
  initialSTarTBack,
  initialNDI,
  initialMentalNursing,
  initialBDI,
  initialBAI,
  initialMMSE,
  initialOperation,
  initialAnesthesia,
  initialTransfusion,
  initialDialysis,
  initialEmergency,
  initialChildbirth,
  initialHomeCare,
  initialDietNutrition,
  initialFallPrevention,
  initialTransfusionAgree,
  initialColonoscopy,
  initialTransfusionAgreement,
  initialNonSalary,
  initialAdmissionCheckList,
  initialDNR,
  initialCoreNursingSkillVideo,
  initialIntakeOutput,
  initialClinicalObservationICU,
  initialIntubation,
  initialCPR,
  initialHospitalConfirm,
  initialCRRT,
  initialIntubationAgree,
  initialCentralVenous,
  initialSuppressor,
  initialSBAR,
  initialSBAR2,
  initialSBAR3,
} from './initialStates';

export enum MENU {
  E_CARDEX = 'e-CARDEX',
  SBAR1 = 'SBAR 1',
  SBAR2 = 'SBAR 2',
  SBAR3 = 'SBAR 3',
  TAKING_OVER = 'Nurse Take Over',
  HOSPITALIZATION = 'Inpatient Record',
  OUT_HOSPITAL = 'Discharge Record',
  PRESCRIPTION = 'Prescription Record',
  NURSE = 'Nursing Record',
  MEDICATION = 'Medication Record',
  RADIOLOGY = 'Radiography Record',
  PATHOLOGY = 'Clinical Pathology',
  CLINICAL_OBSERVATION = 'Vital Sign Record',
  CLINICAL_OBSERVATION_ICU = 'Clinical observation(ICU)',
  IORECORD = 'I/O',
  GLUCOSE = 'Blood Sugar Log',
  BEDSORES = 'Braden Scale',
  NEEDS = 'Needs Assessment',
  FALL = 'Morse Fall Scale',
  FALLSCALE = 'Humty Dumpty \nFall Scale',
  GCS = 'GCS',
  Pediatric_GCS = 'Pediatric GCS',
  FourScore = 'FOUR Score',
  SAFETY = 'Patient Safety Report',
  PAIN = 'Pain Assessment',
  NRS = 'NRS',
  FLACC = 'FLACC Scale',
  CNPS = 'CNPS',
  FFI = 'FFI',
  KOOS = 'KOOS',
  LEFS = 'LEFS',
  NDI = 'NDI',
  STarT_BacknScreening = 'STarT Back\nScreening',
  MENTAL_NURSING = 'Mental Health \nRecords',
  BDI = 'BDI',
  BAI = 'BAI',
  MMSE = 'MMSE',
  COGNITIVE_FUNCTION = '인지기능검사',
  OPERATION = 'Surgery',
  ANESTHESIA = 'Anesthetic',
  TRANSFUSION = 'Transfusion',
  INTUBATION = 'Intubation',
  CPR = 'CPR',
  DIALYSIS = 'Dialysis',
  EMERGENCY = 'Emergency',
  CHILDBIRTH = 'Delivery',
  HOME_CARE = 'Home Care',
  DIET_NUTRITION = 'Diet and Nutrition',
  AGREEMENT = 'Agreement',
  PATIENTSGUIDE = 'Patient Guide',
  PATIENTCONSENTFORM = 'Patient Consent Form',
  TRANSFUSION_AGREE = 'Blood Transfusion',
  CONSCIOUS = 'Conscious Sedation',
  DNR = 'DNR',
  ADMISSION = 'Admission Agreement',
  ADMISSION_CHECKLIST = 'Admission Check List',
  FALL_PREVENTION = 'Fall Prevention',
  CRRT_AGREEMENT = 'CRRT',
  CENTRALVENOUS = 'CVC Insertion',
  INTUBATIONAGREEMENT = 'ETI',
  SUPPRESSOR = 'Physical Restraint',
  ADMISSIONGUIDE = 'Admission Guide',
  DRUGCALCULATOR = 'Drug calculator',
  CORE_NURSING_SKILL_VIDEO = 'Video Upload',
  CORE_NURSING_SKILL_VIDEO_EXEMPLE = 'Screen Settings',
  PATIENT_LABEL_PRINTING = 'Patient Label Printing',
}

export enum RECORD_TYPE {
  NANDA = '0',
  SOAPIE = '1',
  FOCUS_DAR = '2',
  NARRATIVE_RECORD = '3',
  REMARKS = '4',
}

export interface SurveyDialogProps<T>
  extends Omit<SurveyFormProps, 'children'> {
  user_id: number;
  nurseName: string;
  patientInfo: IPatientInfo;
  disabled?: boolean;
  defaultValues: T;
}

export type TECardexDefaultValues = typeof initialECardex;
export type TSBARDefaultValues = typeof initialSBAR;
export type TSBAR2DefaultValues = typeof initialSBAR2;
export type TSBAR3DefaultValues = typeof initialSBAR3;
export type TTakingOverDefaultValues = typeof initialTakingOver;
export type THospitalizationDefaultValues = typeof initialHospitalizationSurvey;
export type TOutHospitalDefaultValues = typeof initialOutHospitalSurvey;
export type TMedicationDefaultValues = typeof initialMedicationSurvey;
export type TRadiologyDefaultValues = typeof initialRadiology;
export type TPathologyDefaultValues = typeof initialPathology;
export type TClinicalObservationDefaultValues =
  typeof initialClinicalObservation;
export type TClinicalObservationICUDefaultValues =
  typeof initialClinicalObservationICU;
export type TGlucoseDefaultValues = typeof initialGlucose;
export type TIntakeOutputDefaultValues = typeof initialIntakeOutput;
export type TBedScoreDefaultValues = typeof initialBedScore;
export type TNeedsDefaultValues = typeof initialNeeds;
export type TFallDefaultValues = typeof initialFall;
export type TPediatric_fallDefaultValues = typeof initialPediatric_fall;
export type TGCSDefaultValues = typeof initialGCS;
export type TPediaTric_GCSDefaultValues = typeof initialPediatric_GCS;
export type TFourScoreDefaultValues = typeof initialFourScore;
export type TSafetyDefaultValues = typeof initialSafety;
export type TNRSDefaultValues = typeof initialNRS;
export type TFLACCDefaultValues = typeof initialFLACC;
export type TCNPSDefaultValues = typeof initialCNPS;
export type TFFIDefaultValues = typeof initialFFI;
export type TKOOSDefaultValues = typeof initialKOOS;
export type TLEFSDefaultValues = typeof initialLEFS;
export type TNDIDefaultValues = typeof initialNDI;
export type TSTarTBackScreeningDefaultValues = typeof initialSTarTBack;
export type TMentalNursingDefaultValues = typeof initialMentalNursing;
export type TBDIDefaultValues = typeof initialBDI;
export type TBAIDefaultValues = typeof initialBAI;
export type TMMSEDefaultValues = typeof initialMMSE;
export type TOperationDefaultValues = typeof initialOperation;
export type TAnesthesiaDefaultValues = typeof initialAnesthesia;
// Patient Guide => 수혈동의서
export type TTransfusionDefaultValues = typeof initialTransfusion;
// Patient Consent Form => 대장내시경으로
export type TColonoscopyDefaultValues = typeof initialColonoscopy;
export type TTransfusionAgreeDefaultValues = typeof initialTransfusionAgree;
// Conscious Sedation => 비급여동의서로
export type TNonSalaryDefaultValues = typeof initialNonSalary;
export type TDNRDefaultValues = typeof initialDNR;
// Admission Agreement=> 입원안내확인서
export type THospitalConfirmDefaultValues = typeof initialHospitalConfirm;
export type TAdmissionCheckListDefaultValues = typeof initialAdmissionCheckList;
export type TFallPreventionDefaultValues = typeof initialFallPrevention;
export type TCRRTDefaultValues = typeof initialCRRT;
export type TCentralVenousDefaultValues = typeof initialCentralVenous;
export type TIntubationAgreeDefaultValues = typeof initialIntubationAgree;
export type TSuppressorDefaultValues = typeof initialSuppressor;

export type TIntubationDefaultValues = typeof initialIntubation;
export type TCPRDefaultValues = typeof initialCPR;
export type TDialysisDefaultValues = typeof initialDialysis;
export type TEmergencyDefaultValues = typeof initialEmergency;
export type TChildbirthDefaultValues = typeof initialChildbirth;
export type THomeCareDefaultValues = typeof initialHomeCare;
export type TDietNutritionDefaultValues = typeof initialDietNutrition;
export type TTransfusionAgreementDefaultValues =
  typeof initialTransfusionAgreement;
export type TCoreNursingSkillVideoDefaultValues =
  typeof initialCoreNursingSkillVideo;
