import { Fragment, useState } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import ShortRowContent from './components/RowContentSub';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const DefaultInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="Basic Information" />

      <RowContainer xs={12}>
        <ShortRowContent title="ADM route">
          <Stack direction="row" spacing={3}>
            <Form.MuiRadioGroup
              disabled={disabled}
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.PATH"
              values={[1, 2, 0]}
              defaultValue={getValues(
                'default_info.hospitalization_path.value'
              )}
              onChange={v =>
                setValue('default_info.hospitalization_path.value', v)
              }
            />
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('default_info.hospitalization_path.input')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="ADM way">
          <Form.MuiRadioGroup
            disabled={disabled}
            i18nKey="HOSPITALIZATION.WAY"
            values={[1, 2, 3]}
            defaultValue={getValues('default_info.hospitalization_way')}
            onChange={v => setValue('default_info.hospitalization_way', v)}
          />
        </ShortRowContent>
        <ShortRowContent title="LOC">
          <Form.MuiRadioGroup
            disabled={disabled}
            i18nKey="HOSPITALIZATION.STATUS"
            values={[1, 2, 3, 4, 5]}
            defaultValue={getValues('default_info.status')}
            onChange={v => setValue('default_info.status', v)}
          />
        </ShortRowContent>
        <ShortRowContent
          title="Level Of 
          Conversation"
        >
          <Form.MuiRadioGroup
            disabled={disabled}
            i18nKey="HOSPITALIZATION.STATUS02"
            values={[1, 2, 3]}
            defaultValue={getValues('default_info.status02')}
            onChange={v => setValue('default_info.status02', v)}
          />
        </ShortRowContent>

        <ShortRowContent title="Date Of Onset">
          <Form.MuiTextField
            type="date"
            InputLabelProps={{ shrink: true }}
            required={false}
            disabled={disabled}
            sx={{ width: '170px' }}
            {...register('default_info.date')}
          />
        </ShortRowContent>

        <ShortRowContent title="P.E.">
          <Stack direction="row" spacing={3}>
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('Height', 'cm') }}
              {...register('default_info.height')}
            />
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('Weight', 'kg') }}
              {...register('default_info.weight')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Vital Sign">
          <Stack direction="row" spacing={3} sx={{ mb: 1 }}>
            <Form.MuiTextField
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('SBP', 'mmHg') }}
              {...register('default_info.SBP')}
            />
            <Form.MuiTextField
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('DBP', 'mmHg') }}
              {...register('default_info.DBP')}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <Form.MuiTextField
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('PR', 'times') }}
              {...register('default_info.PR')}
            />
            <Form.MuiTextField
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('RR', 'times') }}
              {...register('default_info.RR')}
            />
            <Form.MuiTextField
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('BT', '℃') }}
              {...register('default_info.BT')}
            />
            <Form.MuiTextField
              textAlign="right"
              disabled={disabled}
              InputProps={{ ...Form.adornment('SpO2', '%') }}
              {...register('default_info.Sp02')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Chief Complaint">
          <Form.MuiTextField
            disabled={disabled}
            {...register('default_info.joo_ho_so')}
          />
        </ShortRowContent>
        <ShortRowContent title="Motive for ADM">
          <Form.MuiTextField
            multiline
            disabled={disabled}
            InputProps={{ sx: { height: 63 } }}
            inputProps={{ style: { height: '100%' } }}
            {...register('default_info.hospitalization_reason')}
          />
        </ShortRowContent>
      </RowContainer>
    </Fragment>
  );
};

export default DefaultInfo;
