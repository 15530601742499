import { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import MuiDialog from 'components/MuiDialog';
import {
  SurveyDialogProps,
  TOperationDefaultValues,
} from 'routes/Main/Survey/type';
import { updateOperation } from 'apis/survey';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import PatientInfo from './PatientInfo';
import OpInfo from './OpInfo';
import OpContent from './OpContent';

const Operation = (props: SurveyDialogProps<TOperationDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const [opDate, setOpDate] = useState('');

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch, control } =
    useForm({
      defaultValues,
    });

  const onSubmit = (data: TOperationDefaultValues) => {
    const { surgery_information, operation_information, surgery_details } =
      data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      surgical_survey: {
        surgery_information: { ...surgery_information },
        operation_information: {
          ...operation_information,
          position:
            operation_information.position === 'etc'
              ? operation_information.position_etc
              : operation_information.position,
        },
        surgery_details: {
          ...surgery_details,
        },
      },
    };

    updateOperation(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    control,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Surgery Record
        </Typography>
        <PatientInfo
          {...formProps}
          setOpDate={setOpDate}
          patientInfo={patientInfo}
          nurseName={nurseName}
        />
        <OpInfo {...formProps} />
        <OpContent {...formProps} />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default Operation;
