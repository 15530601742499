import Form from 'components/Form';

import { Grid } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'None',
    key: 'assessment.checked1',
  },
];

const Assessment = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="A (Assessment)" />
      <RowContainer
        xs={12}
        sx={{ margin: '20px 0px 30px 0px', marginLeft: '-25px' }}
      >
        <RowContent
          title="Main Clinical Problems of the Patient"
          titleRatio={2.2}
          childrenRatio={9.8}
        >
          <Stack direction="row" spacing={1}>
            {checks.map((v, i) => {
              return (
                <Grid item xs={12}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={0} childrenRatio={12}>
          <Form.MuiTextField
            sx={{ marginTop: '-15px' }}
            required={false}
            fullWidth
            multiline
            minRows={3}
            disabled={disabled}
            placeholder="Please specify the main clinical problems of the patient."
            {...register(`assessment_input1`)}
          />
        </RowContent>
      </RowContainer>
    </>
  );
};

export default Assessment;
