import { Fragment } from 'react';
import { Typography, Grid, Box, Stack } from '@mui/material';
import Form from 'components/Form';

import RowContainer from '../../components/RowContainer';
import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'Subclavian catheter',
    key: 'no1.checked1',
  },
  {
    label: 'Hickman catheter',
    key: 'no1.checked2',
  },
  {
    label: 'PICC : peripherally inserted central catheter',
    key: 'no1.checked3',
  },
  {
    label: 'Chemo-port',
    key: 'no1.checked4',
  },
];

const CVCone = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="1. Definition and type of Central venous catheter" />

      <Box sx={{ margin: '38px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
            Unlike small peripheral veins located in areas such as the hands or
            feet, the central veins refer to large veins such as the subclavian
            vein, jugular vein, and femoral vein that are
            <br />
            connected to the torso and lead to the heart. A central venous
            catheter is a type of catheter inserted into these large central
            veins. Once inserted and functioning well, it can be used
            <br />
            continuously without the need for separate vascular access each time
            treatment involving intravenous injections is required
          </Typography>
          {checks.map((v, i) => {
            return (
              <Grid item xs={11.5} sx={{ margin: '0px 0 0 15px' }}>
                <Form.MuiCheckbox
                  label={v.label}
                  disabled={disabled}
                  defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                  onChange={(_, checked) => {
                    setValue(v.key, checked);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CVCone;
