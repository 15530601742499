import Form from 'components/Form';

import { IFormValues, IFormRegister, IFormWatch } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentNowrap';

import SectionTitle from '../components/SectionTitle';
import MuiCheckbox from './components/CheckboxFallingType';
import MuiCheckboxTwo from './components/CheckboxFallingTypeTwo';
import MuiRadio from './components/MuiRadioFallingType';
import MuiRadioFallingTwo from './components/MuiRadioFallingTwo';
import { Grid, Stack } from '@mui/material';

interface Props extends IFormValues, IFormRegister, IFormWatch {
  disabled?: boolean;
}

const factors1 = [
  {
    label: 'Unsteady Gait',
    value: 'falling_type.patient_risk_factors.check01',
  },
  {
    label: 'Lost Balance',
    value: 'falling_type.patient_risk_factors.check02',
  },
  {
    label: 'Visual Impairment',
    value: 'falling_type.patient_risk_factors.check03',
  },
  {
    label: 'Bowel&Bladder Need',
    value: 'falling_type.patient_risk_factors.check04',
  },
];

const factors2 = [
  {
    label: 'Lighting',
    value: 'falling_type.patient_risk_factors.check05',
  },
  {
    label: 'Floor',
    value: 'falling_type.patient_risk_factors.check06',
  },
  {
    label: 'Cluttered Area',
    value: 'falling_type.patient_risk_factors.check07',
  },
  {
    label: 'Need Item Out Of Reach',
    value: 'falling_type.patient_risk_factors.check08',
  },
];

const factors3 = [
  {
    label: 'etc',
    value: 'falling_type.patient_risk_factors.check09',
  },
];

const physical1 = [
  {
    label: 'No Injury',
    value: 'falling_type.patient_risk_factors_two.check01',
  },
  {
    label: 'Pain',
    value: 'falling_type.patient_risk_factors_two.check02',
  },
  {
    label: 'Bruising',
    value: 'falling_type.patient_risk_factors_two.check03',
  },
  {
    label: 'Hematoma',
    value: 'falling_type.patient_risk_factors_two.check04',
  },
  {
    label: 'Abrasion',
    value: 'falling_type.patient_risk_factors_two.check05',
  },
];

const physical2 = [
  {
    label: 'Laceration',
    value: 'falling_type.patient_risk_factors_two.check06',
  },
  {
    label: 'Sprain/strain',
    value: 'falling_type.patient_risk_factors_two.check07',
  },
  {
    label: 'Dislocation',
    value: 'falling_type.patient_risk_factors_two.check08',
  },
  {
    label: 'fracture',
    value: 'falling_type.patient_risk_factors_two.check09',
  },
  {
    label: 'etc',
    value: 'falling_type.patient_risk_factors_two.check10',
  },
];

const FallingType = (props: Props) => {
  const { disabled, getValues, setValue, register } = props;

  return (
    <>
      <SectionTitle title="Incident Type - Fall or Injury" />
      <RowContainer xs={12}>
        <RowContent
          title="Level of Consciousness"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiRadio
            i18nKey="SAFETY.TYPE.CONSCIOUSNESS.LEVEL"
            values={[1, 2, 3, 4, 5]}
            disabled={disabled}
            defaultValue={getValues('falling_type.consciousness_level')}
            onChange={v => setValue('falling_type.consciousness_level', v)}
            width="auto"
          />
        </RowContent>
        <RowContent title="Ambulation" titleRatio={2} childrenRatio={10}>
          <MuiRadioFallingTwo
            i18nKey="SAFETY.TYPE.ACTIVITY.STATUS"
            values={[1, 2, 3, 4]}
            disabled={disabled}
            defaultValue={getValues('falling_type.activity_status')}
            onChange={v => setValue('falling_type.activity_status', v)}
            width="auto"
          />
        </RowContent>
        <RowContent
          title="Location of incident occurred"
          titleRatio={2}
          childrenRatio={10}
        >
          <Stack direction="row">
            <MuiRadio
              i18nKey="SAFETY.TYPE.PLACE.FALLING.ACCIDENT"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('falling_type.place_falling_accident')}
              onChange={v => setValue('falling_type.place_falling_accident', v)}
              width="auto"
            />
            <Form.MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="Enter"
              {...register('falling_type.place_falling_accident_etc')}
            />
          </Stack>
        </RowContent>
        <RowContent
          title="Factors attributing to incident"
          titleRatio={2}
          childrenRatio={10}
        >
          <Stack direction="row" spacing={1}>
            {factors1.map((v, i) => {
              return (
                <Grid item xs={1.68}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={
                        Boolean(getValues(v.value)) ? [v.label] : []
                      }
                      onChange={(_, checked) => {
                        setValue(v.value, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <Stack direction="row" spacing={1}>
            {factors2.map((v, i) => {
              return (
                <Grid item xs={1.68}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={
                        Boolean(getValues(v.value)) ? [v.label] : []
                      }
                      onChange={(_, checked) => {
                        setValue(v.value, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <Stack direction="row" spacing={1}>
            {factors3.map((v, i) => {
              return (
                <Grid item xs={0.7}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={
                        Boolean(getValues(v.value)) ? [v.label] : []
                      }
                      onChange={(_, checked) => {
                        setValue(v.value, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('falling_type.fall_type')}
            />
          </Stack>
        </RowContent>

        <RowContent title="Physical Injury" titleRatio={2} childrenRatio={10}>
          <Stack direction="row" spacing={1}>
            {physical1.map((v, i) => {
              return (
                <Grid item xs={1.68}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={
                        Boolean(getValues(v.value)) ? [v.label] : []
                      }
                      onChange={(_, checked) => {
                        setValue(v.value, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <Stack direction="row" spacing={1}>
            {physical2.map((v, i) => {
              return (
                <Grid item xs={i === 4 ? 0.7 : 1.68}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={
                        Boolean(getValues(v.value)) ? [v.label] : []
                      }
                      onChange={(_, checked) => {
                        setValue(v.value, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('falling_type.risk_factor_etc')}
            />
          </Stack>
        </RowContent>
        {/* <RowContent title="Physical Injury" titleRatio={2} childrenRatio={10}>
          <MuiCheckboxTwo
            i18nNullKey="ETC"
            disabled={disabled}
            i18nKey="SAFETY.PATIENT.RISK.INJURY"
            values={[1, 2, 3, 4, 5]}
            defaultValue={getValues('falling_type.patient_risk_factors_two')}
            onChange={v => setValue('falling_type.patient_risk_factors_two', v)}
          />
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <Stack direction="row">
            <MuiCheckboxTwo
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="SAFETY.PATIENT.RISK.INJURY"
              values={[6, 7, 8, 9, 0]}
              defaultValue={getValues('falling_type.patient_risk_factors_two')}
              onChange={v =>
                setValue('falling_type.patient_risk_factors_two', v)
              }
            />
            <Form.MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="Enter"
              {...register('falling_type.fall_type')}
            />
          </Stack>
        </RowContent> */}
      </RowContainer>
    </>
  );
};

export default FallingType;
