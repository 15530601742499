import { useForm } from 'react-hook-form';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { Typography } from '@mui/material';
import MuiDialog from 'components/MuiDialog';

import CommonPatientInfo from '../../components/CommonPatientInfo';
import NrsContents from './NrsContents';
import { updateNRS } from 'apis/survey';
import { INRS } from 'apis/survey/type';

import { SurveyDialogProps, TNRSDefaultValues } from '../../type';

const NRS = (props: SurveyDialogProps<TNRSDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    patientInfo,
    nurseName,
    onClose,
    user_id,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { handleSubmit, watch, getValues, setValue, register } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TNRSDefaultValues) => {
    const { nrs_survey } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      nrs_survey: nrs_survey?.map(({ date, time, pain_score }: INRS) => ({
        date,
        time,
        pain_score,
      })),
    };
    // console.log(request);
    updateNRS(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('Saved successfully');
      })
      .catch(e => onFail('Failed to save', e));
  };

  const formProps = {
    disabled,
    watch,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    register,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Typography
        fontSize={16}
        fontWeight="bold"
        align="center"
        sx={{ marginTop: '40px', marginBottom: '40px' }}
      >
        NRS
        <br /> PAIN SCORE 0-10 NUMERICAL RATING
      </Typography>
      <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
      <NrsContents {...formProps} />
    </MuiDialog.SurveyForm>
  );
};

export default NRS;
