import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import MuiTable from 'components/MuiTable';
import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import useTableForm from '../hooks/useTableForm';

import theme from 'styles/theme';

interface Props extends IFormValues, IFormWatch {}

const BedScoreContents = (props: Props) => {
  const { radioGroup, sumValues } = useTableForm(props);

  const columns = [
    { fieldId: 'title', label: 'Category' },
    { fieldId: '0', label: '1point' },
    { fieldId: '1', label: '2point' },
    { fieldId: '2', label: '3point' },
    { fieldId: '3', label: '4point' },
  ];

  const rows = [
    {
      id: 'sensoryPerception',
      title: 'Sensory Perception',
      ...radioGroup({
        key: 'contents.sensoryPerception',
        options: [1, 2, 3, 4],
        i18nKey: 'BED.SCORE.SENSORY.PERCEPTION',
      }),
    },
    {
      id: 'humidity',
      title: 'Moisture',
      ...radioGroup({
        key: 'contents.humidity',
        options: [1, 2, 3, 4],
        i18nKey: 'BED.SCORE.HUMIDITY',
      }),
    },
    {
      id: 'activity',
      title: 'Activity',
      ...radioGroup({
        key: 'contents.activity',
        options: [1, 2, 3, 4],
        i18nKey: 'BED.SCORE.ACTIVITY',
      }),
    },
    {
      id: 'mobility',
      title: 'Mobility',
      ...radioGroup({
        key: 'contents.mobility',
        options: [1, 2, 3, 4],
        i18nKey: 'BED.SCORE.MOBILITY',
      }),
    },
    {
      id: 'nutrition',
      title: 'Nutrition',
      ...radioGroup({
        key: 'contents.nutrition',
        options: [1, 2, 3, 4],
        i18nKey: 'BED.SCORE.NUTRITION',
      }),
    },
    {
      id: 'frictionAndDissolutionForce',
      title: 'Friction and Shear',
      ...radioGroup({
        key: 'contents.frictionAndDissolutionForce',
        options: [1, 2, 3],
        i18nKey: 'BED.SCORE.FRICTION',
      }),
    },
  ];

  const watchSumValues = () => {
    const values = rows.map(({ id }) => Number(props.watch(`contents.${id}`)));
    console.log('욕창', sumValues(values));
    return sumValues(values);
  };

  return (
    <Fragment>
      <SectionTitle title="Braden Scale" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {watchSumValues()}points
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${theme.palette.primary.main}` }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Severe risk:
              </Box>
              Less Than 9
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                High Risk:
              </Box>
              10 - 12
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Moderate risk:
              </Box>
              13 - 14
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Mild risk:
              </Box>
              15 - 18
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default BedScoreContents;
