import { Grid, Box, Typography, useTheme } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const contents = [
  'I acknowledge that the doctor has explained:',
  'my medical condition, the reason why I need to have a transfusion. I understand the risks, including the risks that are specific to me.',
  'other relevant treatment options and their associated risks.',
  'my prognosis and the risks of not having this treatment.',
  'that no guarantee has been made that the treatment will improve my condition even though it has been carried out with due professional care.',
  'if immediate life-threatening events happen during the treatment, they will be treated accordingly.',
];

const checks = [
  {
    label: 'Blood Products Transfusion Consent - Patient Copy',
    key: 'consent.checked01',
  },
  {
    label: 'A Consumer Brochure - Blood who needs it?',
    key: 'consent.checked02',
  },
  {
    label:
      'Blood Transfusion - Answers to some common questions for you and your family.',
    key: 'consent.checked03',
  },
  {
    label: 'Blood Components - A Guide for Patients.',
    key: 'consent.checked04',
  },
];

const contents2 = [
  'I was able to ask questions and raise concerns with the doctor about my condition, the proposed treatment and its risks, and my treatment options. My questions and concerns have \n     been discussed and answered to my satisfaction.',
  'I understand I have the right to change my mind at any time before the treatment, including after I have signed this form but, preferably following a discussion with my doctor.',
];

const FContents = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;
  const { palette } = useTheme();

  return (
    <>
      <SectionTitle title="F. Patient Consent" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '30px',
                  whiteSpace: 'pre',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '30px',
                whiteSpace: 'pre-wrap',
              }}
            >
              • {v}
            </Typography>
          );
        })}
      </Box>
      <Box sx={{ width: '98%', margin: '0px auto 0px 15px' }}>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '30px',
            whiteSpace: 'pre',
          }}
        >
          I have been given the following patient information sheets;
        </Typography>
        <Grid container xs={12}>
          {checks.map((v, i) => (
            <Grid item xs={12}>
              <Form.MuiCheckbox
                label={v.label}
                disabled={disabled}
                defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                onChange={(_, checked) => {
                  setValue(v.key, checked);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ width: '98%', margin: '10px auto 24px auto' }}>
        {contents2.map((v, i) => {
          return (
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
                color: `${palette.primary.main}`,
              }}
            >
              • {v}
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default FContents;
