import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Container, Typography } from '@mui/material';

import MuiDialog from 'components/MuiDialog';
import {
  SurveyDialogProps,
  TCoreNursingSkillVideoDefaultValues,
} from 'routes/Main/Survey/type';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';
import useUser from 'store/user/useUser';
import useStudent from 'store/student/useStudent';

import VideoForm from './VideoForm';
import StudentInfo from './StudentModeInfo';
import ProfModeInfo from './ProfModeInfo';
import axios from 'axios';
import Loading from './Loading';

const CoreNursingSkillVideo = (
  props: SurveyDialogProps<TCoreNursingSkillVideoDefaultValues>
) => {
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { isStudent } = useUser();

  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { student_uuid } = useStudent();

  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues,
  });

  const onSubmit = async (data: TCoreNursingSkillVideoDefaultValues) => {
    const { files } = data;

    let toSaveCount = 0;
    let lastSaveIdx = 0;
    setLoading(true);
    for (let i = 0; i < 3; i++) {
      if (!files[i]['saved'] && files[i]['file'] !== null) {
        toSaveCount++;
        lastSaveIdx = i;
      }
    }

    if (toSaveCount === 0) {
      onUpdateIsSave(true);
      setLoading(false);
      onSuccess('Saved successfully');
      return;
    }

    for (let i = 0; i < 3; i++) {
      if (!files[i]['saved'] && files[i]['file'] !== null) {
        const formData = new FormData();
        formData.append('user_id', String(user_id));
        formData.append('patient_id', String(patientInfo.patient_id));
        formData.append('file', files[i]['file']);

        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/file/video_upload`,
            formData,
            {
              headers: {
                Accept: 'application/json',
                'Content-type':
                  'multipart/form-data;charset=UTF-8; boundary=6o2knFse3p53ty9dmcQvWAIx1zInP11uCfbm',
              },
            }
          )
          .then(res => {
            if (res.data.bucket_url === null) {
              onFail('Failed to save', 'bucket_url is null');
              return;
            }

            if (i === lastSaveIdx) {
              onUpdateIsSave(true);
              setLoading(false);
              onSuccess('Saved successfully');

              setRefresh(!refresh);
              return;
            }
          })
          .catch(e => onFail('Failed to save', e));
      }
    }
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
  };

  return (
    <MuiDialog.SurveyFormTwo
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Container maxWidth="md" sx={{ mt: 7.5, mb: 6 }}>
        {isStudent ? (
          <StudentInfo totalSize={totalSize} />
        ) : (
          <ProfModeInfo totalSize={totalSize} />
        )}
        {loading ? (
          <Loading />
        ) : (
          <VideoForm
            {...formProps}
            user_id={isStudent ? user_id : student_uuid}
            patient_id={patientInfo.patient_id}
            patient_name={patientInfo.name}
            totalSize={totalSize}
            setTotalSize={setTotalSize}
            refresh={refresh}
          />
        )}
        <div style={{ paddingBottom: '50px' }}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              fontWeight: 400,
              borderTop: '0.5px solid lightGray',
              lineHeight: '50px',
            }}
          >
            💡 I can't save the core Nursery video!
          </Typography>
          <Typography
            sx={{
              whiteSpace: 'pre-wrap',
              width: '900px',
              fontSize: '14px',
              height: '80px',
            }}
          >
            If you can't save the video, please check if the video is less than
            500MB!
            <br />
            If it's more than 500MB, I recommend using the site by searching
            Google for "reduce video size".
          </Typography>
        </div>
      </Container>
    </MuiDialog.SurveyFormTwo>
  );
};

export default CoreNursingSkillVideo;
