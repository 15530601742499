import Form from 'components/Form';

import { Grid, Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'MRSA',
    key: 'assessment_contact.checked1',
  },
  {
    label: 'C-Diff',
    key: 'assessment_contact.checked2',
  },
  {
    label: 'ESBL',
    key: 'assessment_contact.checked3',
  },
  {
    label: 'Flu',
    key: 'assessment_contact.checked4',
  },
  {
    label: 'Droplet',
    key: 'assessment_contact.checked5',
  },
  {
    label: 'Neutropenic',
    key: 'assessment_contact.checked6',
  },
];

const checks2 = [
  {
    label: 'Urine CX',
    key: 'assessment_need.checked1',
  },
  {
    label: 'Resp CX',
    key: 'assessment_need.checked2',
  },
  {
    label: 'Flu Swab',
    key: 'assessment_need.checked3',
  },
  {
    label: 'MRSA Swab',
    key: 'assessment_need.checked4',
  },
];

const checks3 = [
  {
    label: 'Daily Weight',
    key: 'assessment_extras.checked1',
  },
  {
    label: 'Strict I/O',
    key: 'assessment_extras.checked2',
  },
  {
    label: 'Fall Risk',
    key: 'assessment_extras.checked3',
  },
  {
    label: 'Observation',
    key: 'assessment_extras.checked4',
  },
  {
    label: '1:1 Sitter',
    key: 'assessment_extras.checked5',
  },
  {
    label: 'NPO Midnight',
    key: 'assessment_extras.checked6',
  },
];

const checks_iv_RL = [
  {
    label: 'R',
    key: 'assessment_iv_rs.checked1',
  },
  {
    label: 'L',
    key: 'assessment_iv_rs.checked2',
  },
];

const checks_iv_site = [
  {
    label: 'AC',
    key: 'assessment_iv_site.checked1',
  },
  {
    label: 'FA',
    key: 'assessment_iv_site.checked2',
  },
  {
    label: 'Hand',
    key: 'assessment_iv_site.checked3',
  },
  {
    label: 'Wrist',
    key: 'assessment_iv_site.checked4',
  },
  {
    label: 'UA',
    key: 'assessment_iv_site.checked5',
  },
  {
    label: 'etc.',
    key: 'assessment_iv_site.checked6',
  },
];

const checks_iv_central = [
  {
    label: 'IJ',
    key: 'assessment_iv_central.checked1',
  },
  {
    label: 'PICC',
    key: 'assessment_iv_central.checked2',
  },
  {
    label: 'Port',
    key: 'assessment_iv_central.checked3',
  },
  {
    label: 'Trialysis',
    key: 'assessment_iv_central.checked4',
  },
];

const Assessment1 = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="A (Assessment)" />
      <RowContainer
        xs={12}
        sx={{ margin: '20px auto 5px auto', marginLeft: '-25px' }}
      >
        <RowContent title="Contact" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Need" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Extras" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            {checks3.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="IV" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={2.7}>
              <Stack direction="row" spacing={3}>
                <Form.MuiTextField
                  required={false}
                  sx={{ width: '190px' }}
                  disabled={disabled}
                  InputProps={{ ...Form.adornment('#', '') }}
                  {...register(`assessment_iv_input`)}
                />
                <Form.MuiTextField
                  required={false}
                  sx={{ width: '190px' }}
                  disabled={disabled}
                  placeholder="Enter"
                  type="date"
                  {...register(`assessment_iv_date`)}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                R/L
              </Typography>
            </Grid>
            {checks_iv_RL.map((v, i) => {
              return (
                <Grid item xs={i === 1 ? 1 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Site
              </Typography>
            </Grid>
            {checks_iv_site.map((v, i) => {
              return (
                <Grid item xs={i === 5 ? 0.5 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              sx={{ width: '90px' }}
              disabled={disabled}
              placeholder="Enter"
              {...register(`assessment_iv_site_input`)}
            />
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.3}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Central
              </Typography>
            </Grid>
            {checks_iv_central.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default Assessment1;
