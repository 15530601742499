import { Box, Stack, Typography } from '@mui/material';
import { getPatientBarcode } from 'apis/admin';
import { IPatientInfo } from 'apis/admin/type';
import useNotification from 'hooks/useNotification';
import { useEffect, useState } from 'react';
import usePatient from 'store/patient/usePatient';

interface Props {
  disabled?: boolean;
  patientInfo: IPatientInfo;
}

const contents = [
  '70세 이상 또는 14세 이하',
  '이뇨제, 진정제, 항경련제 등의 약물을 복용하시는 분',
  '낙상경험, 보행장애, 혼미, 어지러움증이 있으신 분',
  '전신쇠약, 시력 및 청력장애, 배뇨 및 배설장애, 골다공증이 있으신 분',
  '기타 낙상의 위험이 높으신 분',
];

const Barcode = (props: Props) => {
  const {
    disabled,
    patientInfo: { blood },
  } = props;

  const { patient, patientInfo, onSelectedPatientInfo } = usePatient();
  const { onFail } = useNotification();

  // 바코드 변수에 담기
  const [imgBarcode, setImgBarcode] = useState('');

  useEffect(() => {
    if (!patient) return;

    // 가상환자 상세정보 요청
    getPatientBarcode({ patient_id: patient.patient_id })
      .then(({ data }) => {
        setImgBarcode(data);
      })
      .catch(e => {
        onSelectedPatientInfo(null);
        onFail(`가상환자 데이터 조회에 실패했습니다.`, e);
      });
    // eslint-disable-next-line
  }, [patient]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          margin: '18px auto 48px auto',
          justifyContent: 'right',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Stack direction="row">
          <div
            style={{
              border: '1px solid black',
              width: '67px',
              height: '70px',
              textAlign: 'center',
              paddingTop: '26px',
              borderRadius: '4px',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            barcode
          </div>
          <div
            style={{
              border: '1px solid black',
              width: '200px',
              height: '70px',
              textAlign: 'center',
              borderRadius: '4px',
              paddingTop: '5px',
              backgroundColor: '#e4e4e4',
              fontSize: '46px',
            }}
          >
            <img src={imgBarcode} alt="바코드" style={{ height: '40px' }} />
          </div>
        </Stack>
      </Box>
    </>
  );
};

export default Barcode;
