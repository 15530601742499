import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { AccessTime, Delete } from '@mui/icons-material';
import { Button, Grid, IconButton, Checkbox } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import { Ti18nId } from 'hooks/useI18n';
import { IGlucosePrescriptionRecord } from 'apis/survey/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';
import MuiTable from 'components/MuiTable';
import MuiTextField from 'components/Form/MuiTextField';
import SectionTitle from '../components/SectionTitle';

import { formatStringToDate } from 'utils/formatting';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const GlucoseRecords = (props: Props) => {
  const {
    disabled,
    watch,
    setValue,
    onRequired,
    onSuccess,
    register,
    control,
  } = props;
  const prescriptionRecordList: IGlucosePrescriptionRecord[] =
    watch('prescription');

  const [date, setDate] = useState('');
  const [time, setTime] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [unit, setUnit] = useState('');
  const [count, setCount] = useState('');
  const [detail, setDetail] = useState('');
  const [finished, setFinished] = useState(false);

  const columns = [
    { fieldId: 'date', label: 'Date', sx: { width: 200 } },
    { fieldId: 'time', label: 'Time', sx: { width: 200 } },
    { fieldId: 'medication', label: 'Blood Sugar Level', sx: { width: 200 } },
    { fieldId: 'content', label: 'Type of Insulin', sx: { width: 200 } },
    { fieldId: 'unit', label: 'Dose', sx: { width: 100 } },
    {
      fieldId: 'administration_no',
      label: 'Injection Site',
      sx: { width: 100 },
    },
    {
      fieldId: 'methods',
      label: 'Notes',
      sx: { width: 100 },
    },
    { fieldId: 'completed', label: 'Completed', sx: { width: 50 } },
    { fieldId: 'action', label: '', sx: { width: 100 } },
  ];

  const onAddRow = () => {
    const request = {
      date,
      time,
      medication: title,
      content,
      unit,
      administration_no: count,
      methods: detail,
    };

    if (Object.values(request).filter(v => !v).length > 0) {
      return onRequired('REQUIRED.NONCHOICE');
    }

    onSuccess('Added successfully');
    setValue(
      'prescription',
      prescriptionRecordList
        ? [
            ...prescriptionRecordList,
            { ...request, completed: String(finished) },
          ]
        : [{ ...request, completed: String(finished) }]
    );
    setValue('prescription_date', '');
    setDate('');
    setTime(null);
    setTitle('');
    setContent('');
    setUnit('');
    setCount('');
    setDetail('');
    setFinished(false);
  };

  const inputRow = {
    id: 'add-prescription-record',
    date: (
      <Form.MuiTextField
        type="date"
        InputLabelProps={{ shrink: true }}
        required={false}
        disabled={disabled}
        sx={{ width: '170px' }}
        {...register('prescription_date', {
          onChange: e => setDate(e.target.value),
        })}
      />
    ),
    time: (
      <MobileTimePicker
        value={time}
        onChange={setTime}
        renderInput={params => (
          <MuiTextField
            {...params}
            required={false}
            placeholder="00:00"
            InputProps={{ endAdornment: <AccessTime /> }}
          />
        )}
      />
    ),
    medication: (
      <MuiTextField
        value={title}
        required={false}
        onChange={({ target: { value } }) => setTitle(value)}
      />
    ),
    content: (
      <MuiTextField
        value={content}
        required={false}
        onChange={({ target: { value } }) => setContent(value)}
      />
    ),
    unit: (
      <MuiTextField
        value={unit}
        required={false}
        onChange={({ target: { value } }) => setUnit(value)}
      />
    ),
    administration_no: (
      <MuiTextField
        value={count}
        required={false}
        onChange={({ target: { value } }) => setCount(value)}
      />
    ),
    methods: (
      <MuiTextField
        value={detail}
        required={false}
        onChange={({ target: { value } }) => setDetail(value)}
      />
    ),
    completed: (
      <Checkbox
        size="small"
        defaultChecked={false}
        value={finished}
        onChange={e => setFinished(e.target.checked)}
      />
    ),
    action: (
      <Button variant="contained" size="small" onClick={onAddRow}>
        ADD
      </Button>
    ),
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'prescription',
      prescriptionRecordList.filter((_, i) => i !== index)
    );
  };

  const displayRows = prescriptionRecordList
    ? prescriptionRecordList.map((item, i) => ({
        ...item,
        id: i,
        time: formatStringToDate(item.time, 'hh:mm a'),
        completed: (
          <Checkbox
            size="small"
            defaultChecked={item.completed === 'true' ? true : false}
          />
        ),
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{ display: disabled ? 'none' : 'block' }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];

  const tableRow = disabled ? displayRows : [inputRow, ...displayRows];
  console.log(tableRow);

  return (
    <Fragment>
      <SectionTitle title="Record of Medication Administration (Insulin)" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={[...tableRow]} />
      </Grid>
    </Fragment>
  );
};

export default GlucoseRecords;
