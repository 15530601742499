import { format } from 'date-fns/esm';
import { Box, Card, Typography } from '@mui/material';

import { IPatientInfo } from 'apis/admin/type';

interface Props {
  patientInfo: IPatientInfo;
}

const MedicalNote = ({ patientInfo }: Props) => {
  const today = format(new Date(), 'yyyy-MM-dd');

  return (
    <Box flex={1} display="flex" flexDirection="column" overflow="auto">
      <Typography variant="subtitle2" fontSize={13} mb={1}>
        Prescription History
      </Typography>
      <Card
        component="section"
        sx={{ p: '20px 15px', height: '100%', overflow: 'auto' }}
      >
        <Typography variant="caption" component="p" color="#00000080" mb={1}>
          Date and Time of Prescription {today}
        </Typography>
        {patientInfo.medical_note.split('\n').map((item, i) => {
          if (item === '') {
            return <br />;
          }
          return (
            <Typography variant="body2" lineHeight="22px" whiteSpace="pre-wrap">
              {item}
            </Typography>
          );
        })}
      </Card>
    </Box>
  );
};

export default MedicalNote;
