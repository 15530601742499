import { Fragment, useState } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  DvrOutlined,
  Diversity2Outlined,
  Healing,
  HomeOutlined,
  LocalPharmacyOutlined,
  DriveFileRenameOutline,
  MedicationOutlined,
  ImageOutlined,
  TaskOutlined,
  SentimentSatisfiedOutlined,
  RestaurantMenu,
  VerifiedUserOutlined,
  ExpandMore,
  MonitorHeartOutlined,
  MasksOutlined,
  SickOutlined,
  ErrorOutlineOutlined,
  VideocamOutlined,
  PsychologyOutlined,
  AutorenewOutlined,
} from '@mui/icons-material';
import { AiOutlineCalculator } from 'react-icons/ai';

import Survey from '../Survey';
import useSurvey from 'store/survey/useSurvey';
import usePatient from 'store/patient/usePatient';
import useStudent from 'store/student/useStudent';
import useNotification from 'hooks/useNotification';
import { IToggleObj } from './type';
import { initialToggleObj } from './initialStates';

interface Props {
  menuDrawerWidth: number;
  setMenuDrawerWidth: (menuDrawerWidth: number) => void;
  coachRef: any;
}

const MenuRecords = (props: Props) => {
  const { menuDrawerWidth, setMenuDrawerWidth, coachRef } = props;

  const { student_uuid } = useStudent();
  const { patientInfo } = usePatient();
  const { onRequired } = useNotification();
  const { onUpdateSurveyType } = useSurvey();

  const [toggle, setToggle] = useState<IToggleObj>(initialToggleObj);

  const menus = [
    {
      icon: <DvrOutlined />,
      label: 'e-CARDEX',
    },
    {
      disabled: true,
      icon: <AutorenewOutlined />,
      label: 'SBAR',
      id: 'sbar',
    },
    {
      label: 'SBAR 1',
      toggle: toggle.sbar,
    },
    {
      label: 'SBAR 2',
      toggle: toggle.sbar,
    },
    {
      label: 'SBAR 3',
      toggle: toggle.sbar,
    },
    {
      icon: <Diversity2Outlined />,
      label: 'Nurse Take Over',
    },
    {
      icon: <Healing />,
      label: 'Inpatient Record',
    },
    {
      icon: <HomeOutlined />,
      label: 'Discharge Record',
    },
    {
      icon: <LocalPharmacyOutlined />,
      label: 'Prescription Record',
    },
    {
      icon: <DriveFileRenameOutline />,
      label: 'Nursing Record',
    },
    {
      icon: <MedicationOutlined />,
      label: 'Medication Record',
    },
    {
      icon: <ImageOutlined />,
      label: 'Radiography Record',
    },
    {
      icon: <TaskOutlined />,
      label: 'Clinical Pathology',
    },
    {
      disabled: true,
      icon: <MonitorHeartOutlined />,
      label: 'Clinical \nObservation Record',
      id: 'clinical_obv',
    },
    {
      label: 'Vital Sign Record',
      toggle: toggle.clinical_obv,
    },
    {
      label: 'I/O',
      toggle: toggle.clinical_obv,
    },
    {
      label: 'Blood Sugar Log',
      toggle: toggle.clinical_obv,
    },
    {
      label: 'Clinical observation(ICU)',
      toggle: toggle.clinical_obv,
    },
    {
      disabled: true,
      icon: <SentimentSatisfiedOutlined />,
      label: 'Patient Assessment',
      id: 'patient_evaluation',
    },
    {
      label: 'Morse Fall Scale',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Humty Dumpty \nFall Scale',

      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Braden Scale',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Needs Assessment',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'GCS',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Pediatric GCS',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'FOUR Score',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Patient Safety Report',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Patient Safety Reporting \nand Learning System',
      toggle: toggle.patient_evaluation,
    },
    {
      label: 'Adverse Drug Reaction \nReporting System',
      toggle: toggle.patient_evaluation,
    },

    {
      icon: <SickOutlined />,
      disabled: true,
      label: 'Pain Assessment',
      id: 'pain',
    },
    {
      label: 'NRS',
      toggle: toggle.pain,
    },
    {
      label: 'FLACC Scale',
      toggle: toggle.pain,
    },
    // {
    //   label: 'CNPS',
    //   toggle: toggle.pain,
    // },
    {
      label: 'FFI',
      toggle: toggle.pain,
    },
    {
      label: 'KOOS',
      toggle: toggle.pain,
    },
    {
      label: 'LEFS',
      toggle: toggle.pain,
    },
    {
      label: 'NDI',
      toggle: toggle.pain,
    },
    {
      label: 'STarT Back\nScreening',
      toggle: toggle.pain,
    },
    {
      icon: <PsychologyOutlined />,
      disabled: true,
      label: 'Psychiatric',
      id: 'mental_health',
    },
    {
      label: 'Mental Health \nRecords',
      toggle: toggle.mental_health,
    },
    {
      label: 'BDI',
      toggle: toggle.mental_health,
    },
    {
      label: 'BAI',
      toggle: toggle.mental_health,
    },
    {
      label: 'MMSE',
      toggle: toggle.mental_health,
    },
    {
      disabled: true,
      icon: <MasksOutlined />,
      label: 'Special part Record',
      id: 'special',
    },
    {
      label: 'Surgery',
      toggle: toggle.special,
    },
    {
      label: 'Anesthetic',
      toggle: toggle.special,
    },
    {
      label: 'Transfusion',
      toggle: toggle.special,
    },
    {
      label: 'Intubation',
      toggle: toggle.special,
    },
    {
      label: 'CPR',
      toggle: toggle.special,
    },
    {
      label: 'Dialysis',
      toggle: toggle.special,
    },
    {
      label: 'Emergency',
      toggle: toggle.special,
    },
    {
      label: 'Delivery',
      toggle: toggle.special,
    },
    {
      label: 'Home Care',
      toggle: toggle.special,
    },
    {
      icon: <RestaurantMenu />,
      label: 'Diet and Nutrition',
    },
    {
      disabled: true,
      icon: <VerifiedUserOutlined />,
      label: 'Agreement',
      id: 'agreement',
    },
    {
      label: 'Patient Guide',
      toggle: toggle.agreement,
    },
    {
      label: 'Patient Consent Form',
      toggle: toggle.agreement,
    },
    {
      label: 'Blood Transfusion',
      toggle: toggle.agreement,
    },
    {
      label: 'Conscious Sedation',
      toggle: toggle.agreement,
    },
    {
      label: 'DNR',
      toggle: toggle.agreement,
    },
    {
      label: 'Admission Agreement',
      toggle: toggle.agreement,
    },
    {
      label: 'Admission Guide',
      toggle: toggle.agreement,
    },
    {
      label: 'Admission Check List',
      toggle: toggle.agreement,
    },
    {
      label: 'Fall Prevention',
      toggle: toggle.agreement,
    },
    {
      label: 'CRRT',
      toggle: toggle.agreement,
    },
    {
      label: 'CVC Insertion',
      toggle: toggle.agreement,
    },
    {
      label: 'ETI',
      toggle: toggle.agreement,
    },
    {
      label: 'Physical Restraint',
      toggle: toggle.agreement,
    },
    {
      disabled: true,
      icon: <ErrorOutlineOutlined />,
      label: 'Informatics & Nursing',
      id: 'information',
    },
    {
      label: 'Chat GPT',
      toggle: toggle.information,
    },
    {
      label: 'Google Gemini',
      toggle: toggle.information,
    },
    {
      label: 'Cochranelibrary',
      toggle: toggle.information,
    },
    {
      label: 'JBI',
      toggle: toggle.information,
    },
    {
      label: 'DXPLAIN',
      toggle: toggle.information,
    },
    {
      label: 'Patientslikeme',
      toggle: toggle.information,
    },
    {
      label: 'Smartpatients',
      toggle: toggle.information,
    },
    {
      icon: <VideocamOutlined />,
      label: 'Video Upload',
    },
    {
      icon: <AiOutlineCalculator />,
      label: 'Drug calculator',
    },
  ];

  const onClickListItem = (label: string) => {
    if (!student_uuid) return onRequired('REQUIRED.STUDENT');

    if (label === 'Patient Safety Reporting \nand Learning System') {
      window.open('https://psnet.ahrq.gov/');
      return;
    } else if (label === 'Adverse Drug Reaction \nReporting System') {
      window.open('https://who-umc.org/');
      return;
    } else if (label === 'Chat GPT') {
      window.open('https://chat.openai.com/chat');
      return;
    } else if (label === 'Google Gemini') {
      window.open('https://bard.google.com/');
      return;
    } else if (label === 'Cochranelibrary') {
      window.open('https://www.cochranelibrary.com/');
      return;
    } else if (label === 'JBI') {
      window.open('https://jbi.global/');
      return;
    } else if (label === 'DXPLAIN') {
      window.open('https://www.mghlcs.org/projects/dxplain');
      return;
    } else if (label === 'Patientslikeme') {
      window.open('https://www.patientslikeme.com/');
      return;
    } else if (label === 'Smartpatients') {
      window.open('https://www.smartpatients.com/');
      return;
    }

    if (!patientInfo) return onRequired('REQUIRED.PATIENT');
    onUpdateSurveyType(label);
  };

  const onClickDisabledItem = (sublabel: string | undefined) => {
    let newToggle = { ...toggle };

    if (menuDrawerWidth !== 220) {
      if (sublabel) newToggle[sublabel] = true;
      setToggle(newToggle);
      setMenuDrawerWidth(220);
      return;
    }

    if (sublabel) newToggle[sublabel] = !newToggle[sublabel];
    setToggle(newToggle);
  };

  return (
    <Fragment>
      {menus.map(({ icon, label, disabled, toggle, id }) => {
        const onClick = () => {
          if (disabled) onClickDisabledItem(id);
          else onClickListItem(label);
        };

        const MoreIcon = () => {
          if (!disabled) return null;
          return (
            <ExpandMore
              fontSize="small"
              sx={{ color: '#fff', marginRight: '-8px' }}
            />
          );
        };

        return (
          <>
            {menuDrawerWidth !== 220 ? (
              icon ? (
                <ListItem key={label} disablePadding>
                  <ListItemButton onClick={onClick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ) : null
            ) : icon ? (
              <ListItem
                key={label}
                disablePadding
                ref={label === 'Special part Record' ? coachRef : null}
                sx={{ whiteSpace: 'pre-wrap' }}
              >
                <ListItemButton onClick={onClick}>
                  <ListItemIcon sx={{ margin: '6.5px 0 6px 0' }}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: '6.5px 0 0 0' }}
                    primary={label}
                  />
                  <MoreIcon />
                </ListItemButton>
              </ListItem>
            ) : toggle ? (
              <ListItem key={label} disablePadding sx={{ width: '200px' }}>
                <ListItemButton onClick={onClick}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{ whiteSpace: 'pre-wrap' }}
                  />
                  <MoreIcon />
                </ListItemButton>
              </ListItem>
            ) : (
              <></>
            )}
          </>
        );
      })}

      <Survey />
    </Fragment>
  );
};

export default MenuRecords;
