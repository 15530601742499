import { Typography, Box } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';
import RowContent from './components/RowContent';
import RowContainer from '../../components/RowContainer';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const Interpreter = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="A. Interpreter / Cultural Needs" />
      <Box sx={{ width: '98%', margin: '30px auto 24px auto' }}>
        <RowContainer ratio={12} sx={{ mb: 'auto' }}>
          <RowContent
            title="An Interpreter Service is required?"
            titleRatio={10}
            childrenRatio={2}
          >
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('interpreter_check1')}
              onChange={v => setValue('interpreter_check1', v)}
            />
          </RowContent>
          <RowContent
            title="If Yes is a qualified interpreter present?"
            titleRatio={10}
            childrenRatio={2}
          >
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('interpreter_check2')}
              onChange={v => setValue('interpreter_check2', v)}
            />
          </RowContent>
          <RowContent
            title="A Cultural Support Person is required?"
            titleRatio={10}
            childrenRatio={2}
          >
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('interpreter_check3')}
              onChange={v => setValue('interpreter_check3', v)}
            />
          </RowContent>
          <RowContent
            title="If Yes, is a Cultural Support Person present?"
            titleRatio={10}
            childrenRatio={2}
          >
            <Form.MuiRadioGroup
              i18nKey="YESORNO"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('interpreter_check4')}
              onChange={v => setValue('interpreter_check4', v)}
            />
          </RowContent>
        </RowContainer>
      </Box>
    </>
  );
};

export default Interpreter;
