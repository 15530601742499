import { Fragment, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import MuiTable from 'components/MuiTable';
import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import useTableForm from '../hooks/useTableForm';

import theme from 'styles/theme';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const FallContents = (props: Props) => {
  const { radioGroup, sumValues } = useTableForm(props);

  const columns = [
    { fieldId: 'title', label: '', sx: { width: 300 } },
    { fieldId: '0', label: '' },
    { fieldId: '1', label: '' },
    { fieldId: '2', label: '' },
  ];

  const rows = [
    {
      id: 'experience',
      title: 'History of falling',
      ...radioGroup({
        key: 'contents.experience',
        options: [1, 25],
        i18nKey: 'FALL',
      }),
    },
    {
      id: 'diagnosis',
      title: 'Secondary diagosis',
      ...radioGroup({
        key: 'contents.diagnosis',
        options: [1, 15],
        i18nKey: 'FALL',
      }),
    },
    {
      id: 'walkingAids',
      title: 'Ambulatory aid',
      ...radioGroup({
        key: 'contents.walkingAids',
        options: [1, 15, 30],
        i18nKey: 'FALL.WALKING.AIDS',
      }),
    },
    {
      id: 'intravenousLine',
      title: 'IV/ Heparine lock or saline PIID',
      ...radioGroup({
        key: 'contents.intravenousLine',
        options: [1, 20],
        i18nKey: 'FALL',
      }),
    },
    {
      id: 'gait',
      title: 'Gait/transferring',
      ...radioGroup({
        key: 'contents.gait',
        options: [1, 10, 20],
        i18nKey: 'FALL.GAIT',
      }),
    },
    {
      id: 'consciousness',
      title: 'Mental Status',
      ...radioGroup({
        key: 'contents.consciousness',
        options: [1, 15],
        i18nKey: 'FALL.CONSCIOUSNESS',
      }),
    },
  ];

  const watchSumValues = () => {
    const values = rows.map(({ id }) => {
      if (Number(props.watch(`contents.${id}`)) === 1) {
        return Number(0);
      } else {
        return Number(props.watch(`contents.${id}`));
      }
    });
    console.log(values);
    return sumValues(values);
  };

  return (
    <Fragment>
      <SectionTitle title="Morse Fall Scale" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {watchSumValues()} points
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${theme.palette.primary.main}` }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                High Risk :
              </Box>
              51 +
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Medium risk :
              </Box>
              25 - 45
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                Low-risk :
              </Box>
              0 - 24
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default FallContents;
