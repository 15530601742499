import { IFormRegister } from 'routes/Main/type';

import Form from 'components/Form';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import RowContentPreWrap from './components/RowContent';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const Signature = (props: Props) => {
  const { disabled, register } = props;

  const labels = [
    { title: 'Date', variable: 'date' },
    { title: 'Relationship with a patient', variable: 'relationship' },
    {
      title: 'Name of the principal (or\n legal representative)',
      variable: 'name',
    },
    {
      title: 'Name of the principal (or\nlegal representative)',
      variable: 'sign',
    },
  ];

  return (
    <>
      <RowContainer xs={11.5} sx={{ margin: '20px 0px 30px 0px' }}>
        {labels.map(({ title, variable }, _) => (
          <>
            {Array(2)
              .fill(0)
              .map(() => (
                <RowContent titleRatio={1.5} childrenRatio={2.5} />
              ))}
            {variable === 'date' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.6}
                childrenRatio={2.4}
              >
                <Form.MuiTextField
                  type="date"
                  required={false}
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : variable === 'name' || variable === 'sign' ? (
              <RowContentPreWrap
                key={variable}
                title={title}
                titleRatio={1.6}
                childrenRatio={2.4}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  required={false}
                  {...register(`${variable}`)}
                />
              </RowContentPreWrap>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.6}
                childrenRatio={2.4}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  required={false}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}
      </RowContainer>
    </>
  );
};

export default Signature;
