import { Typography, Box } from '@mui/material';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from './SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const contents = [
  '1) During intubation, injuries to the lips, gums, tongue, pharynx, vocal cords, and trachea can occur due to the insertion of the laryngoscope and tube, and there is a risk of broken teeth.',
  '2) Stimulation during the intubation process may lead to high blood pressure and arrhythmias.',
  '3) In non-breathing patients, prolonged intubation may result in hypoxia, leading to severe brain damage, and other organ damage.',
  '4) Fatal bronchospasms due to airway irritation may occur, and aspiration of gastric contents during intubation can cause pneumonia and, in severe cases, lead to suffocation.',
  '5) After intubation, emergency situations may arise due to tube occlusion by airway secretions, tube displacement, and emergency reintubation may be required.',
  '6) Post-intubation complications may include laryngospasm, sore throat, laryngeal edema, vocal cord paralysis, laryngeal granuloma, tracheal stenosis, and other complications.',
];

const Complications = (props: Props) => {
  return (
    <>
      <SectionTitle title="2. Postoperative sequelae or complications." />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => (
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '24px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {v}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default Complications;
