import { Box, Typography } from '@mui/material';

import SectionTitle from '../../components/SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Your doctor has recommended that you have a transfusion of blood or blood products, which are from volunteer donors.',
  'A transfusion is necessary to replace a part of your blood and is given to either:',
  'replace red blood cells to treat or prevent anemia, improve oxygen transport and relieve symptoms of dizziness, tiredness or shortness of breath or',
  'to give you platelets to help stop or prevent bleeding or',
  'to give a plasma product to stop, treat or prevent bleeding.',
  'Transfusion are given via cannula (needle in your vein) or via a central line into your vein. During your transfusion you will be closely monitored for any possible reactions. You will also \nbe regularly checked as to whether you may need another blood transfusion.',
  "The doctor has explained that I have the following medical condition for which I need a transfusion: (Doctor to document in patient's own words)",
];

const BContents = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="B. Why am I having blood transfusion?" />
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 20px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i > 1 && i < 5) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                • {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
            </Typography>
          );
        })}
      </Box>
      <Box
        sx={{
          width: '98%',
          margin: '0px auto 20px auto',
        }}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your medical condition requires the following blood products.
          <br />
          DOCTOR TO LIST PRODUCTS AND THE FREQUENCY OF THE TREATMENTS. (Doctor
          can specify that the frequency may vary during the course of
          treatment)
        </Typography>
      </Box>
    </>
  );
};

export default BContents;
