import Form from 'components/Form';
import MuiTextField from 'components/Form/MuiTextField';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SubRowContent from './RowContent';

import { IPatientInfo } from 'apis/admin/type';
import { IFormRegister } from 'routes/Main/type';

interface Props extends IFormRegister {
  disabled?: boolean;
  nurseName: string;
  patientInfo: IPatientInfo;
}

const PatientStaffInfo = (props: Props) => {
  const {
    patientInfo: {
      patient_id,
      name,
      age,
      gender,
      ward,
      room,
      admin_hod,
      department,
      disease_main,
      main_doctor,
      height,
      weight,
      blood,
    },
    register,
    disabled,
  } = props;

  const infos = [
    { title: 'Patient’s  Name', value: name },
    { title: 'Registration No.', value: patient_id },
    { title: 'Age', value: age },
    { title: 'Gender', value: gender },
    { title: 'Ward', value: ward },
    { title: 'Room', value: room },
    { title: 'Department ', value: department },
    { title: 'HOD', value: admin_hod },
    { title: 'Height', value: height },
    { title: 'Weight', value: weight },
    { title: 'Blood type', value: blood },
    { title: 'Diagnosis', value: disease_main.disease_eng },
  ];

  const staffs = [
    {
      title: 'Surgeon',
      variable: 'surgery_information.operating_surgeon',
    },
    { title: 'Assistant', variable: 'surgery_information.assistant' },
    {
      title: 'Scrub Nurse',
      variable: 'surgery_information.scrubbing_nurse',
    },
    {
      title: 'Circulating Nurse',
      variable: 'surgery_information.circulating_nurse',
    },
  ];

  return (
    <RowContainer xs={12}>
      {infos.map(({ title, value }) =>
        title === 'Gender' ? (
          <RowContent title={title} titleRatio={1} childrenRatio={2}>
            <Form.MuiRadioGroup
              i18nKey="GENDER"
              values={[1, 2]}
              value={Number(value)}
              defaultValue={Number(value)}
            />
          </RowContent>
        ) : (
          <RowContent title={title} titleRatio={1} childrenRatio={2}>
            <Form.MuiTextField value={value} InputProps={{ readOnly: true }} />
          </RowContent>
        )
      )}

      {staffs.map(({ title, variable }, _) => {
        return (
          <RowContent
            key={variable}
            title={title}
            titleRatio={1}
            childrenRatio={2}
          >
            <MuiTextField
              {...register(variable)}
              required={false}
              disabled={disabled}
            />
          </RowContent>
        );
      })}
    </RowContainer>
  );
};

export default PatientStaffInfo;
