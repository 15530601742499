import { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { setConstantValue } from 'typescript';

type Props = TextFieldProps & {
  textAlign?: 'right' | 'left';
};

const MuiTextField = forwardRef(
  ({ textAlign = 'left', ...props }: Props, ref) => {
    return (
      <TextField
        fullWidth
        size="small"
        inputRef={ref}
        variant="outlined"
        inputProps={{ style: { textAlign } }}
        {...props}
      />
    );
  }
);

export default MuiTextField;
