import { IGetSearch, IGetCollegeSearch } from 'apis/type';
import { formatToRequestParameter } from 'utils/formatting';
import apiGateway from '../axios';
import {
  IGetPatientList,
  IGetPatientInfo,
  IGetPatientMemo,
  IGetStudentList,
  IPostPatientMemo,
  IGetPatientBarcode,
} from './type';

// 학생일 때 가져오는 학교 - 환자 리스트
export const getCollegeList = (request: IGetCollegeSearch) => {
  const url = `/admin/college/search?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};

export const getPatients = (request: IGetPatientList) => {
  const searchType = isNaN(Number(request.keyword)) ? 1 : 2;
  const url = `/admin/patients/search/global?${formatToRequestParameter({
    ...request,
    searchType,
  })}`;
  return apiGateway.get(url);
};

export const getPatientInfo = (request: IGetPatientInfo) => {
  const url = `/main/patient_info?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};

export const getPatientBarcode = (request: IGetPatientBarcode) => {
  const url = `main/get_barcode?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};

export const getPatientMemo = (request: IGetPatientMemo) => {
  const url = `/main/memo?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};

export const postPatientMemo = (request: IPostPatientMemo) => {
  const url = `/main/memo?${formatToRequestParameter(request)}`;
  return apiGateway.post(url);
};

// 교수일때 갖고오는 학생 - 환자 리스트
export const getStudentList = (request: IGetStudentList) => {
  const url = `/main/admin/userSearch?size=100&${formatToRequestParameter(
    request
  )}`;
  return apiGateway.get(url);
};

export const getCollegePatientList = (request: IGetPatientList) => {
  const searchType = isNaN(Number(request.keyword)) ? 1 : 2;
  const url = `/main/admin/patientSearch/global?size=100&${formatToRequestParameter(
    {
      ...request,
      searchType,
    }
  )}`;
  return apiGateway.get(url);
};
