import { useState } from 'react';
import {
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowLeft,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import Form from 'components/Form';
import SignUpDialog from 'routes/SignUp/SignUpDialog';
import { IFormValues } from 'routes/Main/type';

interface Props extends IFormValues {
  studentGrade: number;
  studentGender: number;
  register: UseFormRegister<FieldValues>;
  isConfirmPassword: boolean;
  onConfirmPassword: () => void;
}

function MyPageForm(props: Props) {
  const [isTerms, setIsTerms] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const {
    studentGrade,
    studentGender,
    register,
    isConfirmPassword,
    onConfirmPassword,
    getValues,
  } = props;

  const VerificationIcon = () => (
    <CheckCircle
      fontSize="small"
      color="primary"
      sx={{ display: isConfirmPassword ? 'block' : 'none' }}
    />
  );

  const [birth, setBirth] = useState(
    getValues('student_birth').length > 4
      ? getValues('student_birth').slice(0, 4)
      : getValues('student_birth')
  );

  // 이름에 특수문자 넣지못하게 막는
  const [이름, set이름] = useState('');

  const nameRegex = () => {
    let check = /@/;
    return check.test(이름);
  };

  return (
    <Box>
      <Container maxWidth="sm" sx={{ mt: 7.5, mb: 6 }}>
        <Button
          href="/#/"
          size="large"
          color="inherit"
          startIcon={<KeyboardArrowLeft />}
          sx={{ mb: 5, p: 0 }}
        >
          Account settings
        </Button>

        <Stack spacing={2.5}>
          <Form.Item label="Email">
            <Box display="flex" gap={1}>
              <TextField
                fullWidth
                disabled={true}
                {...register('student_id')}
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Form.Item>
          <Form.Item label="Password">
            <Box display="flex" gap={1}>
              <Form.Password
                required
                fullWidth
                helperText="To edit your account information, please enter your current password and click 'CONFIRM'."
                placeholder="Enter your current password"
                sx={{ whiteSpace: 'nowrap' }}
                InputProps={{
                  readOnly: isConfirmPassword,
                  endAdornment: <VerificationIcon />,
                }}
                {...register('password')}
              />
              <Button
                variant={'contained'}
                onClick={onConfirmPassword}
                sx={{
                  width: 200,
                  fontSize: 13,
                  maxHeight: 56,
                  whiteSpace: 'nowrap',
                  display: isConfirmPassword ? 'none' : 'block',
                }}
              >
                CONFIRM
              </Button>
            </Box>
          </Form.Item>

          <Form.Item label="New Password">
            <Form.Password
              required={false}
              fullWidth
              helperText="Please enter at least 8 digits including English + numbers + special symbols."
              {...register('newPassword')}
            />
          </Form.Item>
          <Form.Item label="Repeat new password">
            <Form.Password
              required={false}
              fullWidth
              isHideVisibleBtn
              {...register('newPasswordConfirm')}
            />
          </Form.Item>

          <Form.Item label="Name">
            <TextField
              required
              fullWidth
              helperText={
                nameRegex()
                  ? 'Please be careful not to write your email address.'
                  : ''
              }
              color={nameRegex() ? 'warning' : 'success'}
              {...register('student_name', {
                onChange: e => set이름(e.target.value),
              })}
            />
          </Form.Item>
          <Grid container>
            <Grid item xs={6}>
              <Form.Item label="Gender">
                <Form.MuiRadioGroup
                  i18nKey="GENDER"
                  disabled={true}
                  values={[1, 2]}
                  value={studentGender}
                  defaultValue={studentGender}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label="Position">
                <Form.MuiRadioGroup
                  disabled={true}
                  i18nKey="GRADE"
                  values={[1, 2]}
                  value={studentGrade}
                  defaultValue={studentGrade}
                />
              </Form.Item>
            </Grid>
          </Grid>

          <Form.Item label="School(University/College)">
            <TextField
              disabled={true}
              fullWidth
              {...register('college_name')}
              InputProps={{
                readOnly: true,
                endAdornment: <KeyboardArrowDown />,
              }}
            />
          </Form.Item>
          <Form.Item label="Student number">
            <TextField
              disabled={true}
              fullWidth
              InputProps={{ readOnly: true }}
              {...register('student_no')}
            />
          </Form.Item>
          <Form.Item label="Year of Birth">
            <TextField
              disabled={true}
              fullWidth
              defaultValue={birth}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Form.Item>
          <FormGroup sx={{ mt: 2.5 }}>
            <Typography
              gutterBottom
              variant="body2"
              onClick={() => setIsTerms(true)}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Smart Nurse terms and conditions
            </Typography>
            <Typography
              variant="body2"
              onClick={() => setIsPersonal(true)}
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Terms and conditions to collect and use personal information
            </Typography>
          </FormGroup>
          <Form.Item>
            <Button fullWidth size="large" type="submit" variant="contained">
              Save
            </Button>
          </Form.Item>
        </Stack>
      </Container>

      <SignUpDialog.TermsOfService
        isOpen={isTerms}
        onClose={() => setIsTerms(false)}
      />

      <SignUpDialog.PersonalInfo
        isOpen={isPersonal}
        onClose={() => setIsPersonal(false)}
      />
    </Box>
  );
}

export default MyPageForm;
