import { useState, useEffect } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SubRowContent from './RowContent';

import SectionTitle from '../../components/SectionTitle';

import { Box, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const OperationInfo = (props: Props) => {
  const { disabled, register, watch, setValue, getValues } = props;

  const [postureEtc, setPostureEtc] = useState(0);
  const [methodEtc, setMethodEtc] = useState(0);

  const position_labels = [
    'Supine',
    'Trendelenburg',
    'Reverse Trendelenburg',
    'Fowler’s',
    'Semi-Fowler’s',
    'Lithotomy',
    'Lateral (Left)',
    'Lateral (Right)',
    'Prone',
    'Jackknife',
  ];
  const method_labels = ['Local', 'General', 'Spinal', 'Epidural'];

  const contents = [
    {
      label: 'OP Date',
      element: (
        <Form.MuiTextField
          type="date"
          InputLabelProps={{ shrink: true }}
          required={false}
          disabled={disabled}
          {...register('operation_information.operating_date')}
        />
      ),
    },
    {
      label: 'Preop diagnosis',
      element: (
        <Form.MuiTextField
          {...register('operation_information.operating_department')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Postop Diagnosis',
      element: (
        <Form.MuiTextField
          {...register('operation_information.operating_time')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Name of OP',
      element: (
        <Form.MuiTextField
          {...register('operation_information.operation_name')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Location',
      element: (
        <Form.MuiTextField
          {...register('operation_information.npo_status')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Skin Condition',
      element: (
        <Form.MuiTextField
          {...register('operation_information.emergency_status')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Skin Preparation',
      element: (
        <Form.MuiTextField
          {...register('operation_information.prophylactic_method')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Position',
      element: (
        <Box display="flex">
          <Form.MuiTextField
            select
            required={false}
            disabled={disabled}
            defaultValue={
              [...position_labels, undefined].includes(
                getValues('operation_information.position')
              )
                ? getValues('operation_information.position')
                : 'etc'
            }
            {...register('operation_information.position')}
            onChange={e => {
              if (e.target.value === 'etc') setPostureEtc(1);
              else setPostureEtc(0);
            }}
          >
            {position_labels.map(v => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
            <MenuItem value="etc">Enter</MenuItem>
          </Form.MuiTextField>
          {postureEtc ? (
            <Form.MuiTextField
              {...register('operation_information.position_etc')}
              required={false}
              disabled={disabled}
              placeholder="Enter"
              sx={{ marginLeft: '5px' }}
            />
          ) : null}
        </Box>
      ),
    },

    {
      label: 'NPO',
      element: (
        <Form.MuiTextField
          select
          required={false}
          disabled={disabled}
          defaultValue={getValues('operation_information.asa_class')}
          {...register('operation_information.asa_class')}
        >
          <MenuItem value="NPO">NPO</MenuItem>
          <MenuItem value="None">Not NPO</MenuItem>
        </Form.MuiTextField>
      ),
    },
    {
      label: 'Prophylactic Anti',
      element: (
        <Form.MuiTextField
          select
          required={false}
          disabled={disabled}
          defaultValue={getValues(
            'operation_information.prophylactic_antibiotics'
          )}
          {...register('operation_information.prophylactic_antibiotics')}
        >
          <MenuItem value="injection">Injection</MenuItem>
          <MenuItem value="none">None</MenuItem>
        </Form.MuiTextField>
      ),
    },
    {
      label: 'Allergy',
      element: (
        <Box display="flex">
          <Form.MuiTextField
            {...register('operation_information.operating_allergy')}
            required={false}
            disabled={disabled}
            sx={{ width: '37%' }}
          />
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_xray'
                )}
                {...register('operation_information.preoperative_xray')}
              />
            }
            label="Pre X-ray"
            sx={{ marginLeft: '1vw' }}
          />
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_ekg'
                )}
                {...register('operation_information.preoperative_ekg')}
              />
            }
            label="Pre EKG"
            sx={{ marginLeft: '8vw' }}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (
      ![...position_labels, undefined].includes(
        getValues('operation_information.position')
      )
    ) {
      setValue(
        'operation_information.position_etc',
        getValues('operation_information.position')
      );
      setPostureEtc(1);
    }

    if (
      ![...method_labels, undefined].includes(
        getValues('operation_information.prophylactic_method')
      )
    ) {
      setValue(
        'operation_information.prophylactic_method_etc',
        getValues('operation_information.prophylactic_method')
      );
      setMethodEtc(1);
    }
  }, []);

  return (
    <>
      <SectionTitle title="Surgery Information" />
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => {
          if (label === 'Allergy') {
            return (
              <>
                <RowContent title={label} titleRatio={1} childrenRatio={5}>
                  {element}
                </RowContent>
              </>
            );
          } else if (label === 'Position') {
            return (
              <>
                <RowContent title={label} titleRatio={1} childrenRatio={2}>
                  {element}
                </RowContent>
              </>
            );
          } else {
            return (
              <>
                <SubRowContent title={label} titleRatio={1} childrenRatio={2}>
                  {element}
                </SubRowContent>
              </>
            );
          }
        })}
      </RowContainer>
    </>
  );
};

export default OperationInfo;
